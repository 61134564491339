<template>
<div class="container-fluid">
  <CRow class="">
  <CCol sm="8">
     <CInput
          :value="`${ediFileInfo ? ediFileInfo.EdiFileName :''}`"
           disabled
          :horizontal="{ label: 'col-sm-2', input: 'col-sm-10' }"
          :label="`${$t('label.file')}:`"
          addLabelClasses="text-right"
       />
  </CCol>
  <CCol sm="4">
    <CInput
          :value="`${ediFileInfo ? ediFileInfo.TpEdiTransacName : ''}
          ${!ediFileInfo ? '':ediFileInfo.TpEdiTransacId == '86648E9F-D6C9-4924-940A-B99AFB241468'?'⬇️'
          :ediFileInfo.TpEdiTransacId == '0BDB045B-F7CF-4782-8ACF-AC04A1B342CF'?'⬆️':''}`"
          disabled
          :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
          :label="`${$t('label.transaction')}:`"
          addLabelClasses="text-right"
       />
  </CCol>
  </CRow>
  <CRow class="">
    <CCol sm="6" v-if="showLayout" class="pr-0">
      <table class="info-table">
        <thead>
          <tr>
            <th>{{$t('label.description')}}</th>
            <th>{{$t('label.values')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{$t('label.Sender')}}</td>
            <td>{{this.leftTable[0][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.Recipient')}}</td>
            <td>{{this.leftTable[1][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.TimeOfPreparation')}}</td>
            <td>{{this.leftTable[2][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.ControlReference')}}</td>
            <td>{{this.leftTable[3][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.CommunicationAgreement')}}</td>
            <td>{{this.leftTable[4][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.MessageReferenceNumber')}}</td>
            <td>{{this.leftTable[5][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.MessageTypeIdentifier')}}</td>
            <td>{{this.leftTable[6][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.MessageTypeVersionNo')}}</td>
            <td>{{this.leftTable[7][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.MessageTypeReleaseNumber')}}</td>
            <td>{{this.leftTable[8][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.ControllingAgency')}}</td>
            <td>{{this.leftTable[9][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.AssociationAssignedCode')}}</td>
            <td>{{this.leftTable[10][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.DocumentNumber')}}</td>
            <td>{{this.leftTable[11][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.MessageType')}}</td>
            <td>{{this.leftTable[12][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.MessageCompilationTime')}}</td>
            <td>{{this.leftTable[13][1]}}</td>
          </tr>
        </tbody>
      </table>
    </CCol>
    <CCol sm="6" v-if="showLayout" class="pl-0">
      <table class="info-table">
        <thead>
          <tr>
            <th>{{$t('label.description')}}</th>
            <th>{{$t('label.values')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{$t('label.DischargeVoyageNumber')}}</td>
            <td>{{this.rightTable[0][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.ActualTimeOfArrival')}}</td>
            <td>{{this.rightTable[1][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.PlaceOfDeparture')}}</td>
            <td>{{this.rightTable[2][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.EstimatedTimeOfDeparture')}}</td>
            <td>{{this.rightTable[3][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.NextPortOfCall')}}</td>
            <td>{{this.rightTable[4][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.CarrierCode')}}</td>
            <td>{{this.rightTable[5][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.VesselCallSign')}}</td>
            <td>{{this.rightTable[6][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.VesselLloydsCode')}}</td>
            <td>{{this.rightTable[7][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.IdMeanfTransportIdentification')}}</td>
            <td>{{this.rightTable[8][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.VesselName')}}</td>
            <td>{{this.rightTable[9][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.LoadingVoyageNumber')}}</td>
            <td>{{this.rightTable[10][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.Lines')}}</td>
            <td>{{this.rightTable[11][1]}}</td>
          </tr>
          <tr>
            <td>{{$t('label.Containers')}}</td>
            <td>{{this.rightTable[12][1]}}</td>
          </tr>
        </tbody>
      </table>
    </CCol>
  </CRow>
</div>
</template>

<script>
import { mapState } from "vuex";

// Data
function data() {
  return {
    info: {},
    leftTable: [],
    leftRowNum: 14,
    rightTable: [],
    rightRowNum: 14,
    showLayout: false,
  };
}

// methods:
function getEdiFileInfo() {
  this.$store.state.planificacionestiba.apiStateForm = 1;

  this.$http
    .ejecutar("GET", "EdiFileInformation-by-id", {
      EdiFileId: this.ediFileId,
    })
    .then((response) => {
      this.setData(response.data.data[0]);
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.$store.state.planificacionestiba.apiStateForm = 0;
    });
}
function setData(ediInfo) {
  let infoAux = {...ediInfo};
  delete infoAux.EdiFileId;
  delete infoAux.EdiFileName;
  let arr = Object.entries(infoAux);
  this.leftTable = arr.slice(0, this.leftRowNum);
  this.rightTable = arr.slice(this.rightRowNum);
  this.showLayout = true;
}

export default {
  name: "info-view",
  data,
  methods: {
    getEdiFileInfo,
    setData,
  },
  computed: {
    ...mapState({
      ediFileId: (state) => state.planificacionestiba.EdiFileId,
      ediFileInfo: (state) => state.planificacionestiba.EdiFileInfo,
      dropItem: (state) => state.planificacionestiba.dropItem,
      collapsePlanificacion: (state) => state.planificacionestiba.collapsePlanificacion,
      isHidden: (state) => state.planificacionestiba.isHidden,
    }),
  },
  watch: {
    dropItem: function (newItem) {
      if (newItem == 30) {
        this.getEdiFileInfo();
      }
    },
    collapsePlanificacion: function (newValue) {
      if(!newValue) {
        this.leftTable = [];
        this.rightTable = [];
        this.showLayout = false;
      }
    },
    isHidden: function (newValue) {
      if(!newValue) {
        this.leftTable = [];
        this.rightTable = [];
        this.showLayout = false;
      } else {
        if (this.dropItem == 30 && this.ediFileId != 0) {
          this.getEdiFileInfo();
        }
      }
    },
  },
};
</script>

<style scoped>
.info-table {
  width: 100%;
  border: 1px solid #616161;
  border-collapse: collapse;
}
.info-table thead {
  background-color: #616161;
  color: #ffffff;
}
.info-table th,
.info-table td {
  width: calc(100% / 2);
  border: 1px solid #616161;
  padding: 10px;
}
.info-table th {
  text-align: left;
}

.info-table tr td:nth-child(1),
.info-table tr td:nth-child(3) {
  background-color: #e5e5e5;
}
</style>