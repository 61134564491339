var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CRow',{staticStyle:{"margin-top":"20px"}},[_c('loading-overlay',{attrs:{"active":_vm.loadingOverlay,"is-full-page":true,"loader":"bars"}}),_c('modalDeviceAssign',{attrs:{"modal":_vm.ShowModalDeviceAssign},on:{"closeModal":function($event){_vm.ShowModalDeviceAssign=false},"child-refresh":function($event){_vm.refrescarTabla=true}}}),_c('CCol',{attrs:{"sm":"12"}},[(_vm.transferir)?_c('CCard',{attrs:{"bodyWrapper":""}},[_c('transferir-dispositivo',{attrs:{"valor":_vm.transferir},on:{"child-refresh":function($event){_vm.transferir=false}}})],1):_vm._e(),(!_vm.transferir)?_c('div',{staticClass:"m-3"},[_c('CRow',[_c('CCol',{staticClass:"d-flex align-items-center justify-content-end",attrs:{"sm":"12"}},[_c('CButton',{staticClass:"mr-1",attrs:{"size":"sm","color":"danger"},on:{"click":function($event){return _vm.onBtnPdf()}}},[_c('CIcon',{attrs:{"name":"cil-file-pdf"}}),_vm._v("  PDF ")],1),_c('CButton',{staticClass:"mr-1",attrs:{"size":"sm","color":"success"},on:{"click":function($event){return _vm.onBtnExport(true)}}},[_c('CIcon',{attrs:{"name":"cil-file-excel"}}),_vm._v("  XLSX ")],1)],1),_c('CCol',{staticClass:"d-flex align-items-center justify-content-end mt-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: _vm.$t('label.asignDevice'),
                      placement: 'top-end'
                    }),expression:"{\n                      content: $t('label.asignDevice'),\n                      placement: 'top-end'\n                    }"}],staticClass:"mr-1",attrs:{"color":"add","size":"sm"},on:{"click":function($event){_vm.ShowModalDeviceAssign=true}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_vm._v(_vm._s(_vm.$t('label.nueva'))+" ")],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({ placement:'top-end', content:_vm.$t('label.asignDevice') }),expression:"{ placement:'top-end', content:$t('label.asignDevice') }"}],staticStyle:{"display":"none"}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"sm","fields":_vm.fields,"items":_vm.formatedItems,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"loading":_vm.isLoading},on:{"filtered-items-change":_vm.getFilteredList},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('loading')]},proxy:true},{key:"Status",fn:function({ item }){return [_c('td',{staticClass:"center-cell text-center"},[_c('div',[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t("label." + item.Status))+" ")])],1)])]}},{key:"Details",fn:function({ item }){return [_c('td',[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                              content:
                                _vm.$t('label.edit') + ' ' + _vm.$t('label.asignDevice'),
                              placement: 'top-end',
                            }),expression:"{\n                              content:\n                                $t('label.edit') + ' ' + $t('label.asignDevice'),\n                              placement: 'top-end',\n                            }"}],attrs:{"shape":"square","color":"edit","size":"sm"},on:{"click":function($event){_vm.ShowModalDeviceAssign=item}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1)],1)]}}],null,false,2283754598)})],1)],1),_c('CRow',[_c('CCol',{staticClass:"text-right mt-2 mb-2 pb-2",attrs:{"sm":"12"}})],1)],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }