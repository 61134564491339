<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow>
            <CCol sm="6">
                <h3>
                    {{ this.tituloModal }}
                </h3>        
            </CCol>
            <CCol sm="6" class="text-right">
                <CButton
                    color="wipe"
                    @click="devolver"
                >
                    <CIcon name="returnSolid" class="mr-2" />
                    {{$t('label.return')}}
                </CButton>
            </CCol>
            <CCol sm="12" v-if="mostrar">
                <step-progress :steps="steps" :current-step="currentStep"></step-progress>
            </CCol>
            <CCol sm="12" class="mt-2">
                <keep-alive>
                    <component :is="computedComponents"></component>
                </keep-alive>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import StepProgress from 'vue-step-progress';
    
    //Data
    function data() {
        return {
            loadingOverlay: false,
            tituloModal: '',
            current: '',
            currentStep: 0,
            mostrar: false,
            steps: [''],
            locale: '',
            StowagePlanningEdiMessageId: '',
            retorno:0
        };
    }

    function devolver() {
        if (this.retorno > 0) {
            this.$emit('ret',this.retorno);
        }
    }

    function computedComponents(){
        if (this.current !== '') {
            return () => import(`@/pages/${this.current}`);
        } else {
            return ''
        }
    }

    function submit() {
        this.$http.get("StowagePlanningEdiMessageErrorCheck", { StowagePlanningEdiMessageId: this.StowagePlanningEdiMessageId })
        .then(response => { 
            let listado = [...response.data.data][0];
            this.$store.commit('planificacionEstibaErrores/set', false);
            this.steps = [];
            let texto = "";
            let bandera = true;
            this.mostrar = false;
            this.locale = this.$i18n.locale;
            this.current = '';
            if ((listado.FgFix) && (listado.FgApplyFix)) {
                listado.RouteFixJson.map(item => {
                    texto = this.locale=='es' ? item.RouteNameSpanish : item.RouteNameEnglish;
                    this.steps.push(texto);
                    if (bandera) {
                        this.current = item.RouteFix.replace("\r\n","");
                        bandera = false;
                    }
                });
                
                if (this.RouteFixJson.length > 1) {
                    //this.currentStep = this.value.RouteFixJson.length - 1;
                    this.mostrar = true;
                }
            }
            //console.log("paso por el submit")
        }).catch(err => {
            console.log(err)
        });
    }

    function actualizarPantalla() {
        this.$http.get("StowagePlanningEdiMessageErrorCheck", { StowagePlanningEdiMessageId: this.StowagePlanningEdiMessageId })
        .then(response => { 
            let listado = [...response.data.data][0];
            let pasos = [];
            if (listado != '' && listado != undefined) {
                listado.RouteFixJson.map(item => {
                    let texto = this.locale=='es' ? item.RouteNameSpanish : item.RouteNameEnglish;
                    pasos.push(texto);
                });
                if (this.steps.length != pasos.length) {
                    this.devolver();
                }
            }

        }).catch(err => {
            console.log(err)
        });
        if(!["", null, undefined].includes(this.StowagePlanning) && this.value.valor==31)
            setTimeout(function () { this.actualizarPantalla() }.bind(this), 50000)
    }

    export default {
        name: "errores-view",
        data,
        props: ['value'],
        methods:{
           submit,
           devolver,
           actualizarPantalla
        },
        computed:{
            ...mapState({
                StowagePlanning: state => state.planificacionestiba.planificacionId,
                EdiFileId: state => state.planificacionestiba.EdiFileId,
                dropItem: state => state.planificacionestiba.dropItem,
                actList: state => state.planificacionEstibaErrores.actList,
            }),
            computedComponents
        },
        components:{
            StepProgress
        },
        watch:{
            value: function () {
                if (this.value.valor==31) {
                    this.$store.commit('planificacionEstibaErrores/set', false);
                    this.steps = [];
                    let texto = "";
                    let bandera = true;
                    this.mostrar = false;
                    this.locale = this.$i18n.locale;
                    this.StowagePlanningEdiMessageId = this.value.StowagePlanningEdiMessageId;
                    this.value.RouteFixJson.map(item => {
                        texto = this.locale=='es' ? item.RouteNameSpanish : item.RouteNameEnglish;
                        this.steps.push(texto);
                        if (bandera) {
                            this.current = item.RouteFix.replace("\r\n","");
                            bandera = false;
                        }
                    });
                    
                    if (this.value.RouteFixJson.length > 1) {
                        //this.currentStep = this.value.RouteFixJson.length - 1;
                        this.mostrar = true;
                    }

                    this.tituloModal = this.value.modalTitle;
                    if ((this.tituloModal=="ERRORES") || (this.tituloModal=="ERRORS")) {
                        this.retorno = 2;
                    } else {
                        this.retorno = 1;
                    }
                    //this.$emit('child-refresh',true);
                    this.actualizarPantalla()
                }
            },
            actList: function(nuevo) {
                if (nuevo) {
                    this.submit();
                }     
            },
        },
    };
</script>

<style>
    .step-progress__wrapper{
        width: 80%;
        margin: 10px auto 40px auto;
        position: relative;
    }
    
    .step-progress__wrapper-before{
        background-color:grey;
        transform:translateY(-50%) perspective(1000px)
    }
    
    .step-progress__wrapper-after,.step-progress__wrapper-before{
        content:"";
        position:absolute;
        left:0;
        top:50%;
        height:12px;
        width:100%
    }

    .step-progress__step{
        z-index:2;
        position:relative;
        --activeColor:#5281b2 !important;
        --passiveColor:grey;
        --activeBorder:3px;
        --passiveBorder:3px
    }
    
    .step-progress__wrapper-after{
        background-color:#5281b2 !important;
        transform:scaleX(0) translateY(-50%) perspective(1000px);
        transform-origin:left center;
        transition:transform .5s ease
    }
    
    .step-progress__bar{
        width:100%;
        display:flex;
        justify-content:space-between;
        align-items:center;
    }
    
    .step-progress__step span{
        color:var(--passiveColor);
        transition:.3s ease;
        display:block;
        font-size:15px;
        transform:translateZ(0) scale(1) perspective(1000px);
        text-align:center;opacity:1
    }
    
    .step-progress__step--active .step-progress__step-label,.step-progress__step--active span{
        color:var(--activeColor)
    }
    
    .step-progress__step--active .step-progress__step-icon{
        opacity:1
    }
    
    .step-progress__step--valid .step-progress__step-icon{
        opacity:1;
        transform:translate3d(-50%,-50%,0) scale(1) perspective(1000px)
    }
    
    .step-progress__step--valid span{
        color:var(--activeColor);
        opacity:0;
        transform:translateZ(0) scale(2) perspective(1000px)
    }
    
    .step-progress__step--valid .step-progress__step-label{
        color:var(--activeColor)
    }
    
    .step-progress__step:after{
        content:"";
        position:absolute;
        z-index:-1;
        left:50%;
        top:50%;
        transform:translate(-50%,-50%) perspective(1000px);
        width:75px;
        height:75px;
        background-color:#fff;
        border-radius:50%;
        border:var(--passiveBorder) solid var(--passiveColor);
        transition:.3s ease
    }
    
    .step-progress__step:after{
        width:40px;
        height:40px
    }
    
    .step-progress__step--active:after,.step-progress__step--valid:after{
        border:var(--activeBorder) solid var(--activeColor)
    }
    
    .step-progress__step--valid:after{
        background-color:var(--activeColor)
    }
    
    .step-progress__step-label{
        position:absolute;
        top:calc(100% + 15px);
        left:50%;
        transform:translateX(-50%) perspective(1000px);
        white-space:nowrap;
        font-size:14px;
        font-weight:600;
        color:grey;
        transition:.3s ease
    }
    
    .step-progress__step-icon{
        font-size:36px;
        color:#fff;
        position:absolute;
        left:50%;
        top:50%;
        transition:transform .3s ease;
        opacity:0;
        transform:translate3d(-50%,-50%,0) scale(0) perspective(1000px)
    }
    
    .step-progress__step-icon{
        font-size:17px
    }
</style>