<template>
  <CRow style="margin-top: 20px">
  <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
    <modalDeviceAssign
      :modal = "ShowModalDeviceAssign" 
      @closeModal = "ShowModalDeviceAssign=false" 
      @child-refresh = "refrescarTabla=true"  
    />
        <CCol sm="12">
          <!--CTabs variant="tabs" :active-tab="tabChange" @update:activeTab="handleTab">
            <CTab :title="$t('label.asignDevice')" class="form-especial" -->
              <CCard bodyWrapper v-if="transferir">
                <transferir-dispositivo :valor="transferir" @child-refresh="transferir=false" />
              </CCard>
              <div class="m-3" v-if="!transferir">
                <!--CRow>
                  <CCol sm="12">
                    <CCard bodyWrapper class="form-card">
                      <CRow alignVertical="center" class="pb-2">
                        <CCol sm="6">
                          <div class="form-group form-row" rol="group">
                            <label
                              class="
                                required
                                col-form-label col-sm-3
                                text-right
                              "
                              for="type"
                            >
                              {{ $t("label.role") }}
                            </label>
                            <div class="col-sm-9 input-group-sm">
                              <v-select
                                :placeholder="$t('label.select')"
                                :options="computedRoles"
                                v-model.trim="StowageRoleId"
                                :value.sync="StowageRoleId"
                                :disabled="isArrEmpty(rolesOptions)"
                                @input="getResponsables"
                              />
                            </div>
                          </div>
                          <CElementCover :opacity="1" v-show="isLoadingRoles">
                            <label class="d-inline"
                              >{{ $t("label.load") }}...
                            </label>
                            <CSpinner />
                          </CElementCover>
                        </CCol>
                        <CCol sm="6">
                          <div class="form-group form-row" rol="group">
                            <label
                              class="
                                required
                                col-form-label col-sm-3
                                text-right
                              "
                              for="type"
                            >
                              {{ $t("label.device") }}
                            </label>
                            <div class="col-sm-9 input-group-sm">
                              <v-select
                                :placeholder="$t('label.select')"
                                :options="computedDevice"
                                :is-valid="hasError($v.DeviceId)"
                                v-model.trim="$v.DeviceId.$model"
                                :value.sync="DeviceId"  
                                :disabled="isArrEmpty(deviceOptions)"  
                                :invalid-feedback="$t('label.required')"
                                :class="isDeviceValid"
                              />
                            </div>
                          </div>
                          <CElementCover :opacity="1" v-show="isLoadingDevice">
                            <label class="d-inline"
                              >{{ $t("label.load") }}...
                            </label>
                            <CSpinner />
                          </CElementCover>
                        </CCol>
                        <CCol sm="6">
                          <div class="form-group form-row" rol="group">
                            <label
                              class="
                                required
                                col-form-label col-sm-3
                                text-right
                              "
                              for="type"
                            >
                              {{ $t("label.responsible") }}
                            </label>
                            <div class="col-sm-9 input-group-sm">
                              <v-select
                                :placeholder="$t('label.select')"
                                :options="computedResponsable"
                                v-model.trim="StowagePlanningRoleDetId"
                                :value.sync="StowagePlanningRoleDetId"  
                                :disabled="isArrEmpty(responsableOptions)"  
                                @input="getLocation"
                                :invalid-feedback="$t('label.required')"
                                :class="isResponsableValid"
                              />
                            </div>
                          </div>
                          <CElementCover
                            :opacity="1"
                            v-show="isLoadingResponsable"
                          >
                            <label class="d-inline"
                              >{{ $t("label.load") }}...
                            </label>
                            <CSpinner />
                          </CElementCover>
                        </CCol>
                        <CCol sm="6">
                          <CInput
                            v-model.trim="StowageUbicationName"
                            disabled
                            v-uppercase
                            :horizontal="{
                              label: 'col-sm-3',
                              input: 'col-sm-9',
                            }"
                            size="sm"
                            :label="$t('label.location')"
                            addLabelClasses="text-right"
                          />
                        </CCol>
                        <CCol sm="6" v-if="actualizar" >
                          <div class="form-group form-row" rol="group">
                            <label
                              class="col-form-label col-sm-3 text-right"
                            >
                              {{ $t('label.status') }}
                            </label>
                            <div class="col-sm-9 input-group-sm">
                              <CSelect
                                v-model="Status"
                                :value.sync="Status"
                                :is-valid="statusSelectColor"
                                :options="statusOptions"
                              />
                            </div>
                          </div>
                        </CCol>
                      </CRow>
                    </CCard>
                  </CCol>
                </CRow-->
               

                <!--CRow>
                  <CCol sm="12" class="d-flex align-items-start justify-content-end mb-2">
                    <CButton
                      color="add"
                      v-c-tooltip="{
                        content: $t('label.add'),
                        placement: 'top-end',
                      }"
                      :disabled="isSubmitInvalid"
                      v-if="!actualizar"
                      @click="guardar"
                    >
                      <div v-if="!isSubmit">
                        <CIcon name="checkAlt" />
                        <span class="ml-1">{{ $t("label.add") }}</span>
                      </div>
                      <div v-if="isSubmit">
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="ml-1">{{ $t("label.add") }}</span>
                      </div>
                    </CButton>
                    <CButton
                      color="watch"
                      v-c-tooltip="{
                        content: $t('label.edit'),
                        placement: 'top-end',
                      }"
                      v-if="actualizar"
                      :disabled="isSubmitInvalid"
                      @click="evaluaStatus"
                    >
                      <div v-if="!isSubmit">
                        <CIcon name="checkAlt"/>
                        <span class="ml-1">{{$t('label.edit')}}</span>
                      </div>
                      <div v-if="isSubmit">
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="ml-1">{{$t('label.edit')}}</span>
                      </div>
                    </CButton>
                     <CButton
                        shape="square"
                        color="wipe"
                        class="d-flex align-items-center ml-2"
                        v-c-tooltip="{
                          content: $t('label.clearFields'),
                          placement: 'top-end'
                        }"
                        @click="limpiarDatos()" 
                      >
                        <CIcon name="cil-brush-alt"/>
                      </CButton>
                  </CCol>
                </CRow-->
                <CRow>
                  <CCol sm="12" class="d-flex align-items-center justify-content-end ">
                    <CButton 
                        size="sm"
                        color="danger"
                        class="mr-1"
                        @click="onBtnPdf()"
                        >
                        <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                    </CButton>
                    <CButton
                        size="sm"
                        color="success"
                        class="mr-1"
                        @click="onBtnExport(true)"
                    >
                        <CIcon name="cil-file-excel"/>&nbsp; XLSX
                    </CButton>
                    
                  </CCol>
                  <CCol sm="12" class="d-flex align-items-center justify-content-end mt-2">
                    <CButton
                      color="add"
                      class="mr-1"
                      @click="ShowModalDeviceAssign=true" 
                      size="sm"
                      v-c-tooltip="{
                        content: $t('label.asignDevice'),
                        placement: 'top-end'
                      }"
                      >
                      <CIcon name="cil-playlist-add"/>{{ $t('label.nueva')}}
                    </CButton>
                    <CButton style="display: none;" v-c-tooltip="{ placement:'top-end', content:$t('label.asignDevice') }"></CButton>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol sm="12">
                    <dataTableExtended
                      class="align-center-row-datatable"
                      size="sm"
                      :fields="fields"
                      :items="formatedItems"
                      :items-per-page="5"
                      column-filter
                      pagination
                      :table-filter="tableText.tableFilterText"
                      :items-per-page-select="tableText.itemsPerPageText"
                      :noItemsView="tableText.noItemsViewText"
                      :loading="isLoading"
                      @filtered-items-change="getFilteredList"
                    >
                      <template #loading>
                        <loading />
                      </template>
                      <template #Status="{ item }">
                        <td class="center-cell text-center">
                          <div>
                            <CBadge :color="getBadge(item.Status)">
                              {{ $t("label." + item.Status) }}
                            </CBadge>
                          </div>
                        </td>
                      </template>
                      <template #Details="{ item }">
                        <td>
                            <CButton
                              shape="square"
                              color="edit"
                              size="sm"
                              v-c-tooltip="{
                                content:
                                  $t('label.edit') + ' ' + $t('label.asignDevice'),
                                placement: 'top-end',
                              }"
                              @click="ShowModalDeviceAssign=item"
                            >
                              <CIcon name="pencil" />
                            </CButton>
                        </td>
                      </template>
                    </dataTableExtended>
                  </CCol>
                </CRow>
                <CRow>
                   <CCol sm="12" class="text-right mt-2 mb-2 pb-2">
                      <!--CButton 
                          color="danger"
                          class="mr-1"
                          @click="onBtnPdf()"
                          >
                          <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                      </CButton>
                      <CButton
                          color="success"
                          class="mr-1"
                          @click="onBtnExport(true)"
                      >
                          <CIcon name="cil-file-excel"/>&nbsp; XLSX
                      </CButton-->
                      <!--<CButton
                        square
                        color="edit"
                        @click="transferir=true"
                        class="ml-5"
                      >
                        <CIcon name="cil-exit-to-app" />
                        <span class="ml-1">{{$t('label.transfer')}}</span>
                      </CButton>-->
                    </CCol>
                </CRow>
              </div>
            <!--/CTab>
          </CTabs-->
        </CCol>
  </CRow>
</template>
<script>
import { mapState } from "vuex";
import { DateFormater } from "@/_helpers/funciones";
import UpperCase from "@/_validations/uppercase-directive";
import { tableTextTranslatedHelpers } from "@/_helpers/funciones";
import GeneralMixin from "@/_mixins/general";
import Reportes from '@/_mixins/reportes';
import AsignarValidations from "@/_validations/planificacion-estiba/asignarDispositivoValidations";
import TransferirDispositivo from './transferir-dispositivo';
import ArchivosTransferidos from './archivos-transferidos';
import modalDeviceAssign from './modal-device-assign';

//data
function data() {
  return {
    tabChange: 0,
    isLoading: false,
    isSubmit: false,
    loadingOverlay: false,
    refrescarTabla: false,
    items: [],
    rolesOptions: [],
    deviceOptions: [],
    responsableOptions: [],
    DeviceId: "",
    StowageRoleId: "",
    StowagePlanningRoleDetId: "",
    isLoadingResponsable: false,
    isLoadingDevice: false,
    isLoadingRoles: false,
    StowageUbicationId: '',
    StowageUbicationName: '',
    actualizar: false,
    Status: 1,
    transferir: false,
    dataEs: [],
    dataEn: [],

    ShowModalDeviceAssign: '',
    filteredList: [],
  };
}

//Created


//methods
function handleTab(tab) {
  this.tabChange = tab;
}
function getRoles() {
  this.isLoadingRoles = true;
  this.rolesOptions = [];

  let StowagePlanningId = this.planificacionId;
  this.$http
    .ejecutar("GET", "StowagePlanningRoleDevice-list", {
      StowagePlanningId: StowagePlanningId,
    })
    .then((response) => {
      this.rolesOptions = [...response.data.data];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoadingRoles = false;
    });
}

function getDevices() {
  this.isLoadingDevice = true;

  let StowagePlanningId = this.planificacionId;
  this.$http
    .ejecutar("GET", "StowagePlanningDeviceList-by-StowagePlanningId", {
      StowagePlanningId: StowagePlanningId,
    })
    .then((response) => {
      this.deviceOptions = response.data.data || [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.isLoadingDevice = false;
    });
}

function getResponsables(role) {
  this.isLoadingResponsable = true;
  this.responsableOptions = [];

  if (role != null) {
    let StowagePlanningId = this.planificacionId;
    this.$http
      .ejecutar("GET", "StowagePlanningRoleDetail-by-StowageRoleId", {
        StowagePlanningId: StowagePlanningId,
        StowageRoleId: role.value,
      })
      .then((response) => {
        this.responsableOptions = response.data.data || [];
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
      .then(() => {
        this.isLoadingResponsable = false;
      });
  } else {
    this.isLoadingResponsable = false;
  }
}

function getLocation(event) {
  if (event) {
    this.StowageUbicationName = event.StowageUbicationName;
    this.StowageUbicationId = event.StowageUbicationId;
  }  
}

function loadData() {
  this.isLoading = true;
  this.items = [];
   
  let StowagePlanningId = this.planificacionId;
  this.$http
  .ejecutar("GET", "DeviceStowagePlanningUser-list", { StowagePlanningId: StowagePlanningId })
  .then((response) => {
    let listado = response.data.data || []; 
    this.items = listado;
    let arregloEs = [];
    let arregloEn = [];
    let _this = this.$i18n.locale;

      listado.forEach(function (item) {      
        arregloEn.push({
          '#': item.Nro,
          RESPONSIBLE: item.RoleDetLastName+' '+item.RoleDetName,
          ID: item.RoleDetLogin,
          ROLE: item.StowageRoleName,
          LOCATION: item.StowageUbicationName,
          DEVICE: item.DeviceName,
          CODE: item.DeviceCod,
          DATE: DateFormater.formatDateTimeWithSlash(item.TransaRegDate),
          USER: item.TransaLogin,
          STATUS: item.Status,  
        }); 
      })
      this.dataEn = arregloEn;
   
      listado.forEach(function (item) { 
          arregloEs.push({
            '#': item.Nro,
            RESPONSABLE: item.RoleDetLastName+' '+item.RoleDetName,
            CEDULA: item.RoleDetLogin,
            ROL: item.StowageRoleName,
            UBICACION: item.StowageUbicationName,
            DISPOSITIVO: item.DeviceName,
            CODIGO: item.DeviceCod,
            FECHA: DateFormater.formatDateTimeWithSlash(item.TransaRegDate),
            USUARIO: item.TransaLogin,
            ESTATUS: item.Status,  
          }); 
      });
      this.dataEs = arregloEs;   
  })
  .catch((err) => {
    this.$notify({
      group: "container",
      title: "¡Error!",
      text: err,
      type: "error",
    });
  })
  .then(() => {
    this.isLoading = false;
  });
}

function hasError(field) {
  return field.$dirty ? !field.$error : null;
}

function isArrEmpty(arr) {
  return arr.length == 0;
}

function limpiarDatos() {
  this.DeviceId = '';
  this.StowageRoleId = '';
  this.StowageUbicationId = '';
  this.StowageUbicationName = '';
  this.StowagePlanningRoleDetId = '';
  this.StowagePlanningUserId = '';
  this.Status = 1;
  this.actualizar = false;
  this.$nextTick(() => { this.$v.$reset() })
}

function evaluaStatus() {
  if (this.Status===0) {
    this.$swal.fire({
      text: `${this.$t('label.changeStatusQuestion')}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: '#42AA91',
      cancelButtonColor: '#E1373F',
      confirmButtonText: this.$t('button.confirm'),
      cancelButtonText: this.$t('button.cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.guardar();
      }
    });  
  } else this.guardar();
}

function guardar () {
  if (this.$v.$invalid) return false;
  let DeviceUserJson = [];
  let ruta = '';
  let metodo = '';
  
  this.loadingOverlay = true;
  if (this.StowagePlanningUserId!='') {
    DeviceUserJson = {
      StowagePlanningUserId: this.StowagePlanningUserId,
      StowagePlanningRoleDetId: this.StowagePlanningRoleDetId.value,
      DeviceId: this.DeviceId.value,
      Status: this.Status
    };
    metodo = 'PUT';
    ruta = "DeviceStowagePlanningUser-update";
  } else {
    DeviceUserJson.push({
      StowagePlanningRoleDetId: this.StowagePlanningRoleDetId.value,
      DeviceId: this.DeviceId.value,
    });

    metodo = 'POST';
    ruta = "DeviceStowagePlanningUser-insert";
  }

  this.$http.ejecutar(metodo, ruta, DeviceUserJson, { root: 'DeviceUserJson' })
  .then(response => {
    let res = [...response.data.data];
    this.$notify({
      group: 'container',
      title: '¡Exito!',
      text: res[0].Response,
      type: "success"
    });
    this.loadingOverlay= false;
    this.limpiarDatos();
    this.loadData();
  }).catch((err) => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
    this.loadingOverlay= false;
  });
}

async function editDatos(item) {
  this.StowagePlanningUserId = item.StowagePlanningUserId;
  this.StowageRoleId = {
      value: item.StowageRoleId,
      label: item.StowageRoleName,
  }
  await this.getResponsables(this.StowageRoleId);
  this.DeviceId = {
    value: item.DeviceId,
    label: item.DeviceName,
  };
  
  this.StowagePlanningRoleDetId = {
      value: item.StowagePlanningRoleDetId,
      label: item.RoleDetLastName+', '+item.ResponsableName+' ('+item.RoleDetLogin+')',
      StowageUbicationId: item.StowageUbicationId,
      StowageUbicationName: item.StowageUbicationName
  }
  this.Status = item.Status == 'ACTIVO' ? 1 : 0;
  this.actualizar = true;
  this.$nextTick(() => { this.$v.$touch() });
}
//HACE FALTA NUEVA FUNCION DE REPORTE
async function onBtnPdf(){
    if(this.items.length !== 0) {
      this.loadingOverlay = true;
      let _this = this.$i18n.locale;
      let rowData = this.localeList(_this);
      await this.getPdfArray(rowData,this.$t('label.asignDevice'));
      this.loadingOverlay = false;
    }else{
      this.$notify({  
        group: 'container',
        title: '¡Error!',
        text: this.$t('label.noRecordsAvailbleReport'),
        type: "error" 
      });
    }
}

async function onBtnExport(valor) {
    if(this.items.length !== 0){   
      this.loadingOverlay = true;
      let _this = this.$i18n.locale;
      let rowData = this.localeList(_this);
      await this.getExcelArray(rowData,this.$t('label.asignDevice'),valor);
      this.loadingOverlay = false;
    }else{
      this.$notify({  
        group: 'container',
        title: '¡Error!',
        text: this.$t('label.noRecordsAvailbleReport'),
        type: "error" 
      });
    }  
}
function getFilteredList(arr) {
    this.filteredList = arr;
}
function localeList(locale) {
  if(locale == 'en'){
    let arregloEn = [];
    this.filteredList.forEach(function (item) {      
      arregloEn.push({
        '#': item.Nro,
        RESPONSIBLE: item.RoleDetLastName+' '+item.RoleDetName,
        ID: item.RoleDetLogin,
        ROLE: item.StowageRoleName,
        LOCATION: item.StowageUbicationName,
        DEVICE: item.DeviceName,
        CODE: item.DeviceCod,
        DATE: DateFormater.formatDateTimeWithSlash(item.TransaRegDate),
        USER: item.TransaLogin,
        STATUS: item.Status,  
      }); 
    })
    return arregloEn;
  } else {
    let arregloEs = [];
    this.filteredList.forEach(function (item) { 
        arregloEs.push({
          '#': item.Nro,
          RESPONSABLE: item.RoleDetLastName+' '+item.RoleDetName,
          CEDULA: item.RoleDetLogin,
          ROL: item.StowageRoleName,
          UBICACION: item.StowageUbicationName,
          DISPOSITIVO: item.DeviceName,
          CODIGO: item.DeviceCod,
          FECHA: DateFormater.formatDateTimeWithSlash(item.TransaRegDate),
          USUARIO: item.TransaLogin,
          ESTATUS: item.Status,  
        }); 
    });
    return arregloEs; 
  }  
}
//computed
function fields() {
  return [
    { key: "RowNumber",filter:false, label: "#", _style: "width:2%; text-align:center;", _classes: 'text-center', },
    { key: "ResponsableName", label: this.$t('label.responsible'),  _style: 'width:12%;', _classes: 'text-uppercase' },
    { key: "Cedula", label: this.$t('label.IdNumber'),  _style: 'width:10%; text-align:center', _classes: 'text-uppercase text-center' },
    { key: "RoleName", label: this.$t('label.role'),  _style: 'width:10%; text-align:center', _classes: 'text-uppercase text-center' },
    { key: "Location", label: this.$t('label.location'),  _style: 'width:10%; text-align:center', _classes: 'text-uppercase text-center' },
    { key: "DeviceName", label: this.$t('label.device'),  _style: 'width:10%; text-align:center', _classes: 'text-uppercase text-center' },
    { key: "Cod", label: this.$t('label.code'),  _style: 'width:10%; text-align:center', _classes: 'text-uppercase text-center' },
    { key: "TransaLogin", label:this.$t('label.user'),  _style: 'width:10%; text-align:center', _classes: 'text-uppercase text-center' },
    { key: "FormatedDate", label:this.$t('label.date'), _style: 'width:10%; text-align:center', _classes: 'text-uppercase text-center' },
    { key: "Status", label:this.$t('label.status'), _style: 'width:10%; text-align:center;', _classes: 'text-uppercase text-center' },
    { key: 'Details', label: '',sorter: false,filter: false,_style: 'min-width:45px; width:5%; text-align:center text-center'},
  ];
}

function computedRoles() {
  if (this.rolesOptions.length > 0) {
    return this.rolesOptions.map((item) => {
      return {
        value: item.StowageRoleId,
        label: item.StowageRoleName,
      };
    });
  }
}

function computedDevice() {
  if (this.deviceOptions.length > 0) {
    return this.deviceOptions.map((item) => {
      return {
        value: item.DeviceId,
        label: item.DeviceName,
      };
    });
  }
}

function computedResponsable() {
  if (this.responsableOptions.length > 0) {
    return this.responsableOptions.map((item) => {
      return {
        value: item.StowagePlanningRoleDetId,
        label: item.LastName+', '+item.Name+' ('+item.Login+')',
        StowageUbicationId: item.StowageUbicationId,
        StowageUbicationName: item.StowageUbicationName
      };
    });
  }
}

function isDeviceValid() {
  return this.DeviceId == '' ? 'no-valido' : 'valido';
}
function isResponsableValid() {
  return this.StowagePlanningRoleDetId == '' ? 'no-valido' : 'valido';
}
function cellTableClasses() {
  return {
    RowNumber: 'center-cell-vertical text-center',
    ResponsableName: 'center-cell-vertical text-center',
    Cedula: 'center-cell-vertical text-center',
    RoleName: 'center-cell-vertical text-center',
    Location: 'center-cell-vertical text-center',
    DeviceName: 'center-cell-vertical text-center',
    Cod: 'center-cell-vertical text-center',
    FormatedDate: 'center-cell-vertical text-center',
    TransaLogin: 'center-cell-vertical text-center',
  }
}
function formatedItems() {
  return this.items.map((item) => Object.assign({}, item, {
    RowNumber: item.Nro,
    ResponsableName: item.RoleDetLastName+' '+item.RoleDetName,
    Cedula: item.RoleDetLogin,
    RoleName: item.StowageRoleName,
    Location: item.StowageUbicationName,
    DeviceName: item.DeviceName,
    Cod: item.DeviceCod,
    FormatedDate: item.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(item.TransaRegDate) : 'N/A',
    TransaLogin: item.TransaLogin?item.TransaLogin:'N/A',
    FgActStowagePlanningUser: item.FgActStowagePlanningUser,
    Status: item.Status,
    _classes: item.Status != 'ACTIVO'? 'table-danger': '',
    //_cellClasses: this.cellTableClasses,
  }));
}

function statusOptions() {
  return [
    {
      value: 1,
      label: this.$t('label.ACTIVO')
    },
    {
      value: 0,
      label: this.$t('label.INACTIVO')
    }
  ]
}

function isSubmitInvalid() {
  return this.$v.$invalid || this.isSubmit;
}
function tableText() {
  return tableTextTranslatedHelpers(this);
}

export default {
  name: "asignar-dispositivo",
  data,
  props: {},
  mixins: [GeneralMixin,Reportes],
  directives: UpperCase,
  validations() {
    return AsignarValidations();
  },
  components: {
    TransferirDispositivo,
    ArchivosTransferidos,
    modalDeviceAssign
  },
  methods: {
    handleTab,
    hasError,
    isArrEmpty,
    loadData,
    getResponsables,
    getRoles,
    getDevices,
    getLocation,
    limpiarDatos,
    guardar,
    editDatos,
    evaluaStatus,
    onBtnPdf,
    onBtnExport,
    getFilteredList,
    localeList,
  },
  computed: {
    fields,
    isSubmitInvalid,
    tableText,
    isResponsableValid,
    isDeviceValid,
    cellTableClasses,
    computedRoles,
    computedResponsable,
    computedDevice,
    formatedItems,
    statusOptions,
    ...mapState({
      planificacionId: (state) => state.planificacionestiba.planificacionId,
      collapsePlanificacion: (state) =>
        state.planificacionestiba.collapsePlanificacion,
      isHidden: (state) => state.planificacionestiba.isHidden,
      tabIndex: (state) => state.planificacionestiba.tabIndex,
      user: (state) => state.auth.user,
    }),
  },
  watch: {
    tabIndex(newIndex) {
      if (newIndex === 6) {
        this.limpiarDatos();
        this.loadData();
        this.getRoles();
        this.getDevices();
      }
    },

    collapsePlanificacion: function (collapse) {
      if (!collapse) {
        this.$store.state.planificacionestiba.isHidden = false;
        this.limpiarDatos();
      }
    },
    isHidden: function (newValue) {
      if (!newValue) {
        this.$store.state.planificacionestiba.EdiFileId = 0;
        this.$store.state.planificacionestiba.EdiFileInfo = null;
      }
    },
    docTypeId: function (newId) {
      if (newId) {
        this.getTransactionTypes(newId);

        let tpEdi = this.selectDocType.find((item) => item.TpEdiId == newId);
        this.formArchivos.Format = tpEdi ? tpEdi.Format : "";
      }
    },
    refrescarTabla: function () {
      if (this.refrescarTabla) {
        this.refrescarTabla = false;
        this.loadData();
      }
    },
  },
};
</script>

<style scoped>
.center-cell-vertical {
  text-align: center;
  vertical-align: middle !important;
}

.form-card {
  background-color: #edf1f6;
  border: 1px solid #a3a4a4;
}
</style>