<template>
    <CRow style="margin-top: 20px">
        <CCol sm="12">
            <CTabs variant="tabs" :active-tab="tabChange" @update:activeTab="handleTab">
                <CTab :title="$t('label.generalList')"  class="form-especial">
                    <index-recurso :value="tabChange" />
                </CTab>
                <CTab :title="$t('label.assignResource')" class="form-especial">
                    <asignacion-recurso :value="tabChange" />
                </CTab>
            </CTabs>
        </CCol>
    </CRow>
</template>

<script>
    import { mapState } from "vuex";
    import IndexRecurso from './index-recurso';
    import AsignacionRecurso from './asignacion-recurso';
    //data
    function data() {
        return {
            tabChange: 3,
        }
    }
    //methods
    function handleTab(tab) {
        this.tabChange = tab;
    }
    export default {
        name:'tabs-recurso',
        data,
        methods:{
            handleTab
        },
        computed:{
            ...mapState({
                tabIndex: (state) => state.planificacionestiba.tabIndex,
            })
        },
        components:{
            IndexRecurso,
            AsignacionRecurso
        },
        watch:{
            tabIndex(newValue,OldValue){
                if(newValue === 4){
                    this.tabChange = 0;
                }
                if (OldValue === 4) {
                    this.tabChange = 3;
                }
            }
        }
    }
</script>