<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start px-2">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.listOfReeferContainers') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end px-2">
                <CButton 
                    color="danger"
                    class="mr-1"
                    size="sm"
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>    
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    add-label-classes="text-right"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)"      
                    v-model="search"     
                >
                </CInput> 
            </CCol>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-5 col-xl-5 text-right', input: 'col-sm-10 col-lg-7 col-xl-7 px-0'}"
                    label="U.M"
                    :options="unitMeasureOptions"
                    v-model.trim="unitMeasureId"
                    :value.sync="unitMeasureId"
                    @change="getReeferList"
                />
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field">
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
            <CCol sm="11" lg="12" xl="12" class="px-2 mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowClass="getRowClass"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import Reportes from '@/_mixins/reportes';
    import AgGrid from '@/_mixins/ag-grid';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            unitMeasureId: '',
            unitMeasureOptions: [],
            rows: [],
            formatedItems: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
           
            columnsOption:{
                size:true,
                pod:true,
                weigth:true,
                class:true,
                setting:true,
                height:true,
                status:true,
                delivery:false,
                opt_port:true,
                IsoCode: true,
                Seals: true,
                DeliveryPort:true,
                Status: true,
                TpCargoDetailCode:true,
                LoadPort:true,
                DischargePort:true,
                ShippingLineCode:true,
                ShippingLineName:true,
                ImdgCode:true,
                Yard: true,
                Procedence: true,
                DirectDischarge: true,
            },
            pageSize:'',
            search:'',
            labelUnitMeasure: 'TON',
            nulo:'',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function getRowClass(params) {
        if (params.node.data.Port=="TOTAL") {
            return 'bg-edit text-white font-weight-bold'
        }
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    async function getUnitMeasureWeigthList () {
        this.loadingOverlay = true;
        this.unitMeasureOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("UnitMeasureWeigth-list", { Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.unitMeasureOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.unitMeasureOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }     
            this.unitMeasureId = listado[0].UnitMeasureId;       
            this.loadingOverlay = false;
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            this.loadingOverlay = false;
        });
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        let rowData = [];
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            let computedItems = [];
            rowData.map((item)=>{
                computedItems.push({
                    IdX: item.IdX,
                    BayCode: item.BayCode ? item.BayCode : '',
                    BaySlot: item.BaySlot ? item.BaySlot : '',
                    ContainerCode: item.ContainerCode ? item.ContainerCode : '',
                    Size: item.Size ? item.Size : '',
                    LoadPort: item.LoadPort ? item.LoadPort : '',
                    DischargePort: item.DischargePort ? item.DischargePort  : '',
                    Weigth: formatMilDecimal(Number.parseFloat(item.Weigth).toFixed(2)),
                    ShippingLineCode: item.ShippingLineCode ? item.ShippingLineCode : '',
                    ShippingLineName: item.ShippingLineName ? item.ShippingLineName : '',
                    Setting: item.Setting ? item.Setting : '',
                    Unit: item.Unit ? item.Unit : '',
                    MinimumRangeRf: item.MinimumRangeRf ? item.MinimumRangeRf : '',
                    MaximumRangeRf: item.MaximumRangeRf ? item.MaximumRangeRf : '',
                    PackGroup: item.PackGroup ? item.PackGroup : '',
                    Commodity: item.Commodity ? item.Commodity : '',
                    Status: item.Status ? item.Status : '',
                    TpCargoDetailCode: item.TpCargoDetailCode ? item.TpCargoDetailCode : '',
                    ImdgCode: item.ImdgCode ? item.ImdgCode : '',
                    Seals: item.Seals ? item.Seals : '', 
                    IsoCode: item.IsoCode ? item.IsoCode : '', 
                    DeliveryPort: item.DeliveryPort ? item.DeliveryPort : '', 
                    Procedence: item.Procedence,
                    YardNamePlanning: item.YardNamePlanning, 
                    FgDirect: item.FgDirect
                })
            })
            await this.getPdf(computedItems,this.$t('label.listOfPlannedReeferContainers'));
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }   
        this.loadingOverlay = false; 
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true;
        if(this.nulo!="null" && this.formatedItems.length !== 0){
            let rowData = [];
            this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
            await this.getExcel(rowData,this.$t('label.listOfPlannedReeferContainers'),valor);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }    
        this.loadingOverlay = false;
    }

    function buscarUnidad(e) {
        this.unitMeasureId=e.target.value;
        this.getReeferList();
    }

    async function getReeferList () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array;
        let i = 1;

        let CargoSumaryJson = {
            StowagePlanningId: this.StowagePlanning,
            EdiFileId: this.EdiFileId,
            UnitMeasureId: this.unitMeasureId
        };
        this.labelUnitMeasure = this.unitMeasureId ? this.unitMeasureOptions.filter(ob => ob.value == this.unitMeasureId)[0].label : 'TON';
        await this.$http.post("StowagePlanningCargoReefer-list", CargoSumaryJson, { root: 'CargoSumaryJson' })
        .then(response => {
            listado = [...response.data.data];
            this.nulo = String(listado[0].Json);
            if (this.nulo=="null") return false;

            this.formatedItems = listado.map(listado => Object.assign({}, this.formatedItems, {
                IdX: i++,
                BayCode: listado.BayCode ? listado.BayCode : '',
                BaySlot: listado.BaySlot ? listado.BaySlot : '',
                ContainerCode: listado.ContainerCode ? listado.ContainerCode : '',
                Size: listado.Size ? listado.Size : '',
                LoadPort: listado.LoadPort ? listado.LoadPort : '',
                DischargePort: listado.DischargePort ? listado.DischargePort  : '',
                Weigth: listado.Weigth,
                ShippingLineCode: listado.ShippingLineCode ? listado.ShippingLineCode : '',
                ShippingLineName: listado.ShippingLineName ? listado.ShippingLineName : '',
                Setting: listado.Setting ? listado.Setting : '',
                Unit: listado.Unit ? listado.Unit : '',
                MinimumRangeRf: listado.MinimumRangeRf ? listado.MinimumRangeRf : '',
                MaximumRangeRf: listado.MaximumRangeRf ? listado.MaximumRangeRf : '',
                PackGroup: listado.PackGroup ? listado.PackGroup : '',
                Commodity: listado.Commodity ? listado.Commodity : '',
                Status: listado.Status ? listado.Status : '',
                TpCargoDetailCode: listado.TpCargoDetailCode ? listado.TpCargoDetailCode : '',
                ImdgCode: listado.ImdgCode ? listado.ImdgCode : '',
                Seals: listado.Seals ? listado.Seals : '', 
                IsoCode: listado.IsoCode ? listado.IsoCode : '', 
                DeliveryPort: listado.DeliveryPort ? listado.DeliveryPort : '', 
                Procedence: listado.Procedence,
                YardNamePlanning: listado.YardNamePlanning, 
                FgDirect: listado.FgDirect? 'YES' : 'NO'
            }));


        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }
// computed
    function columnDefs(){
        let columnDefs = [
                 {
                    field: "IdX",
                    headerName: "#",
                    filter: 'agNumberColumnFilter',
                    //lockVisible: true,
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 70,
                    //pinned: 'left',
                    cellClass: 'gb-cell',
                    //checkboxSelection: true, 
                },
                {
                    field: "BayCode",
                    headerName: "BAY",
                    //lockVisible: true,
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    minWidth: 80,
                    //pinned: 'left',
                    cellClass: 'gb-cell',
                    //checkboxSelection: true, 
                },
                {
                    field: "BaySlot",
                    headerName: "SLOT",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 150,
                    //pinned: 'left',
                    cellClass: 'gb-cell',
                },
                {
                    field: "ContainerCode",
                    headerName: "CONTAINER",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 150,
                    //pinned: 'left',
                    cellClass: 'gb-cell',
                },
                {
                    field: "TpCargoDetailCode",
                    headerName: "SIZE (FT)",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "IsoCode",
                    headerName: "ISO CODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "Seals",
                    headerName: "SEALS",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "Status",
                    headerName: "STATUS",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "ShippingLineCode",
                    headerName: "CARRIER CODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "ShippingLineName",
                    headerName: "CARRIER",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "ImdgCode",
                    headerName: "IMDG CODE",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "LoadPort",
                    headerName: "LOAD PORT",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "DischargePort",
                    headerName: "DISCHARGE PORT",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    minWidth: 180,
                },
                {
                    field: "DeliveryPort",
                    headerName: "DELIVERY PORT",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "Weigth",
                    headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 160,
                    cellClass: 'center-cell-especial',
                    cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' && params.value !== 0){
                        variable = parseFloat(params.value).toFixed(2);
                    }
                    return new Intl.NumberFormat("de-DE").format(variable);
                    },
                    filter: "agNumberColumnFilter",
                },
                {
                    field: "Setting",
                    headerName: "REEFER SETTING",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 180,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "Unit",
                    headerName: "UNIT",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 100,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "MinimumRangeRf",
                    headerName: "MINIMUM RANGE RF",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 180,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "MaximumRangeRf",
                    headerName: "MAXIMUM RANGE RF",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 180,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "PackGroup",
                    headerName: "PACKAGING GROUP",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    sortable: true,
                    lockPosition: true,
                    minWidth: 150,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "Commodity",
                    headerName: "COMMODITY",
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    minWidth: 180,
                    cellClass: 'center-cell-especial',
                },
            ]

            if(this.columnsOption.Yard){
                columnDefs.push({
                    field: "YardNamePlanning",
                    headerName: "YARD",
                    headerClass: 'center-cell-especial',
                    cellClass: 'center-cell-especial',
                    minWidth: 150,
                    filter: "agTextColumnFilter",
                });
            }

            if(this.columnsOption.Procedence){
                columnDefs.push({
                    field: "Procedence",
                    headerName: "PROCEDENCE",
                    headerClass: 'center-cell-especial',
                    cellClass: 'center-cell-especial',
                    minWidth: 150,
                    filter: "agTextColumnFilter",
                });
            }

            if(this.columnsOption.DirectDischarge){
                columnDefs.push({
                    field: "FgDirect",
                    headerName: "DIRECT DISCHARGE",
                    headerClass: 'center-cell-especial',
                    cellClass: 'center-cell-especial',
                    minWidth: 150,
                    filter: "agTextColumnFilter",
                });
            }

      
        return columnDefs;
    }
    export default {
        name: "reefer-list",
        data,
        beforeMount,
        mounted,
        props: ['value'],
        mixins: [Reportes, AgGrid],
        methods:{
            onGridReady,
            onBtnExport,
            getReeferList,
            getUnitMeasureWeigthList,
            buscarUnidad,
            onQuickFilterChanged,
            onPageSizeChanged,
            getRowClass,
            onBtnPdf,
        },
        computed:{
            columnDefs,
            ...mapState({
                StowagePlanning: state => state.planificacionestiba.planificacionId,
                EdiFileId: state => state.planificacionestiba.EdiFileId,
                dropItem: state => state.planificacionestiba.dropItem,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                user: state => state.auth.user,
                ItineraryId: state => state.planificacionestiba.ItineraryId,
            }),
        },
        components:{
            
        },
        watch:{
            value: async function () {
                if (this.value==8) {
                    this.pageSize='';
                    this.unitMeasureId='';
                    this.search=''; 
                    this.onQuickFilterChanged(this.search);
                    this.gridApi.paginationSetPageSize(parseInt(50));
                    await this.getUnitMeasureWeigthList();
                    await this.getReeferList();
                    //this.listarPuertos();
                    this.$emit('child-refresh',true);
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>

<style>
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .bg-cell{
        background: #dddddd;
        text-align: center;
        vertical-align: middle;
    }
    .bg-turqueza{
        background: #cafbf3;
    }

    .texto-gris {
        background: #E7E6E6;
    }
    .titulo-boton{
        margin-top: -1.5rem !important;    
        margin-right: 0.25rem !important;
    }

    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>