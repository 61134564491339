<template>
    <div class="sizeGruas">
        <CModalExtended
            size="xl"
            color="dark"
            @update:show="cerrarModal(false, Refresh)"
            :closeOnBackdrop="false"
            :title="$t('label.PlannedHoursCrane')+': '+(CraneName?CraneName:'')"
            :show.sync="$store.state.planificacionestiba.modalGruas"
            class="modal-extended"
        >
            <loading-overlay
                :active="isLoading"
                :is-full-page="true"
                loader="bars"
            />
            <CCollapse :show="true">
                <CCard>
                    <CCardHeader class="text-center dark text-white">
                        <CCol v-if="!isEdit" sm="12" class="d-flex ">{{$t('label.assign')+' '+$t('label.HoursRange')}}</CCol>
                        <CCol v-else sm="12" class="d-flex ">{{$t('label.edit')+' '+$t('label.HoursRange')+': '+
                            (itemRangeHrs.DateFrom?itemRangeHrs.DateFrom:'')+' - '+(itemRangeHrs.DateTo?itemRangeHrs.DateTo:'')}}</CCol>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol sm="12" lg="5">
                                <div class="form-group form-row mb-0">
                                    <label class="col-sm-12 col-lg-4 text-right required mt-2">{{$t('label.from')}}</label>
                                    <div class="input-group col-sm-12 col-lg-8">
                                        <vue-datepicker 
                                        type="datetime"
                                        header
                                        :lang="this.$i18n.locale"
                                        :editable="false"
                                        :clearable="false"
                                        format="DD/MM/YYYY HH:mm"
                                        placeholder="DD/MM/YYYY HH:mm"
                                        time-title-format="DD/MM/YYYY HH:mm"
                                        v-model="DateFrom"
                                        :disabled-date="validateDateRange"
                                        :append-to-body="false"
                                        value-type="format"
                                        :show-second="false"
                                        >
                                        <template #input>
                                            <CInput
                                                class="w-100 float-left"
                                                :value="DateFrom"
                                                placeholder="DD/MM/YYYY HH:mm"
                                                :is-valid="hasError($v.DateFrom)"
                                                @blur="$v.DateFrom.$touch()"                     
                                            >
                                            <template #append-content>
                                                <CIcon name="cil-calendar" />
                                            </template>
                                            </CInput>
                                        </template>
                                        <template #icon-calendar>
                                            <div style="display: none"></div>
                                        </template>
                                        </vue-datepicker>
                                    </div>
                                </div>
                            </CCol>
                            <CCol sm="12" lg="5">
                                <div class="form-group form-row mb-0">
                                    <label class="col-sm-12 col-lg-4 text-right required mt-2">{{$t('label.to')}}</label>
                                    <div class="input-group col-sm-12 col-lg-8">
                                        <vue-datepicker 
                                        type="datetime"
                                        header
                                        :lang="this.$i18n.locale"
                                        :editable="false"
                                        :clearable="false"
                                        format="DD/MM/YYYY HH:mm"
                                        placeholder="DD/MM/YYYY HH:mm"
                                        time-title-format="DD/MM/YYYY HH:mm"
                                        v-model="DateTo"
                                        :disabled-date="validateDateRange"
                                        :append-to-body="false"
                                        value-type="format"
                                        :show-second="false"
                                        >
                                        <template #input>
                                            <CInput
                                                class="w-100 float-left"
                                                :value="DateTo"
                                                placeholder="DD/MM/YYYY HH:mm"
                                                :is-valid="hasError($v.DateTo)"
                                                @blur="$v.DateTo.$touch()"                
                                            >
                                            <template #append-content>
                                                <CIcon name="cil-calendar" />
                                            </template>
                                            </CInput>
                                        </template>
                                        <template #icon-calendar>
                                            <div style="display: none"></div>
                                        </template>
                                        </vue-datepicker>
                                    </div>
                                </div>
                            </CCol>
                            <CCol sm="12" lg="auto" class="text-right">
                                <CButton
                                    square
                                    color="add"
                                    class="mr-2"
                                    size="sm"
                                    @click="isEdit?statusConfirmation(originStatus, formAsignGrua.FgAct, registerData):registerData()"
                                    v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}"
                                >
                                    <CIcon name="checkAlt" />
                                </CButton>
                                <CButton
                                    color="wipe"
                                    size="sm"
                                    v-c-tooltip="{content: $t('label.clearFields'), placement: 'top-end'}" 
                                    @click="resetInputs()"
                                >
                                    <CIcon name="cil-brush-alt" />
                                </CButton>
                            </CCol>
                            <CCol v-if="isEdit" sm="12" lg="5">
                                <CSelect
                                    :options="statusOptions"
                                    :value.sync="formAsignGrua.FgAct"
                                    v-model.trim="$v.formAsignGrua.FgAct.$model"
                                    :label="$t('label.status')"
                                    :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                    addLabelClasses="required text-right"
                                    :is-valid="statusSelectColor"
                                />
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCollapse>
            <CRow>
                <CCol sm="12">
                    <dataTableExtended
     class="align-center-row-datatable"
                        :items="formatedItems"
                        :fields="fields"
                        :table-filter="tableText.tableFilterText"
                        :items-per-page-select="tableText.itemsPerPageText"
                        :noItemsView="tableText.noItemsViewText"
                        :items-per-page="5"
                        pagination
                        column-filter
                        hover
                        sorter
                    >
                        <template #loading>
                            <loading/>
                        </template>
                        <template #StowagePlanningCraneId="{ index}">
                            <td>
                                {{index+1}}
                            </td>
                        </template>   
                        <template #Status="{item}">
                            <td class="text-center">
                                <CBadge v-if="item.status" color="success">
                                    {{item.Status}}
                                </CBadge>
                                <CBadge v-if="!item.status" color="danger">
                                    {{item.Status}}
                                </CBadge>
                            </td>
                        </template>
                        <template #acciones="{item,index}">
                            <td>
                                <CButton
                                    color="edit"
                                    square
                                    size="sm"
                                    class="mr-1"
                                    @click="editTable(item,index)"
                                    v-c-tooltip="{
                                        content: $t('label.edit')+' '+$t('label.workShift'),
                                        placement: 'top-end'
                                    }"
                                >
                                    <CIcon name="pencil"/>
                                </CButton>
                            </td>
                        </template>
                    </dataTableExtended>
                </CCol>
            </CRow>
            <div slot="footer">
                <CButton
                    color="wipe"
                    @click="cerrarModal(false, Refresh)"
                    class="m-2"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.exit')}}
                </CButton>
            </div>
        </CModalExtended>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import { FormAsignGrua } from '@/_validations/estiba/PlanificacionEstibaFormValidations';
    import GeneralMixin from '@/_mixins/general';
    import ModalMixin from '@/_mixins/modal';
    import { DateFormater, tableTextTranslatedHelpers } from '@/_helpers/funciones';
    import moment from 'moment';
import { emit } from 'process';

    //data
    function data() {
        return {
            currentDate: '',
            DateFrom: '',
            DateTo: '',
            previousDate: '',
            laterDate: '',
            ValidPreviousDate: '',
            ValidLaterDate: '',
            CraneName: '',
            Refresh: false,
            isLoading:false,
            isEdit: false,
            itemRangeHrs: {},
            itinerarySelected: {},
            originStatus: false,
            formAsignGrua:{
                StowagePlanningCraneId:"",
                TurnJson:[],
                FgAct:0,
            },
        }
    }

    //methods
    function fields(){
        return [
            { key: 'StowagePlanningCraneId', label: '#',_style: 'width:1%', filter: false},
            { key: 'DateFromNew',label: this.$t('label.DFrom'), _style:'width:20%; text-align:center', _classes: 'text-center' },
            { key: 'DateToNew',label: this.$t('label.DTo'), _style:'width:20%; text-align:center', _classes: 'text-center' },
            { key: 'TotalHour',label: 'EST.HRS', _style:'width:15%; text-align:center', _classes: 'text-center' },
            { key: 'TransaLogin',label: this.$t('label.user'), _style:'width:15%; text-align:center', _classes: 'text-center' },
            { key: 'TransaFromDate',label: this.$t('label.date'), _style:'width:15%; text-align:center', _classes: 'text-center' },
            { key: 'Status',label: this.$t('label.status'), _style:'width:10%; text-align:center',  _classes: 'text-center' },
            { 
                key: 'acciones', 
                label: '', 
                _style: 'min-width:45px; width:1%',
                sorter: false, 
                filter: false
            }
        ];
    }
    function cerrarModal(filtre, Refresh){
        this.$store.state.planificacionestiba.modalGruas = false;
        this.$emit('set-list', filtre, Refresh)    
    }
    function statusSelectColor() {
        return this.formAsignGrua.FgAct === 1;
    }
    function registerData(){
        try {
            this.isLoading = true;
            this.$v.$touch();
            if (this.$v.$error) {
                this.isSubmit=false;
                if ((DateFormater.formatDateTimeWithoutSlash(this.DateFrom)<this.ValidPreviousDate && this.itinerarySelected.Eta!=undefined && this.DateFrom != '')
                    || DateFormater.formatDateTimeWithoutSlash(this.DateTo)<this.ValidPreviousDate && this.itinerarySelected.Eta!=undefined && this.DateTo != '') {
                    throw this.$t('label.ErrorEtaPleaseCheck');
                }
                if(DateFormater.formatDateTimeWithoutSlash(this.currentDate)>DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Etd))){
                    if ((DateFormater.formatDateTimeWithoutSlash(this.DateFrom)>this.ValidLaterDate && this.DateFrom != '')
                        || DateFormater.formatDateTimeWithoutSlash(this.DateTo)>this.ValidLaterDate && this.DateTo != '') {
                        throw this.$t('label.ErrorCurrentDatedPleaseCheck');
                    }
                }else if ((DateFormater.formatDateTimeWithoutSlash(this.DateFrom)>this.ValidLaterDate && this.itinerarySelected.Etd!=undefined && this.DateFrom != '')
                    || DateFormater.formatDateTimeWithoutSlash(this.DateTo)>this.ValidLaterDate && this.itinerarySelected.Etd!=undefined && this.DateTo != '') {
                    throw this.$t('label.ErrorEtdPleaseCheck');
                }
                if ((DateFormater.formatDateTimeWithoutSlash(this.DateFrom) >= DateFormater.formatDateTimeWithoutSlash(this.DateTo))
                    && this.DateFrom != '' && this.DateTo != '') {
                    throw this.$t('label.ErrorFromToPleaseCheck');
                }
                throw this.$t('label.errorsPleaseCheck');
            }
            
            let valores = {};
            let link = '';
            let data = '';

            if(this.$store.state.planificacionestiba.tabGruaIndex === 0){
                link = "StowagePlanningCrane-insert";
                data = "StowagePlanningCraneJson";

                if(!this.isEdit){
                    valores = {
                        StowagePlanningId: this.planificacionId,
                        VesselCraneId: this.grua.VesselCraneId,
                        StowageUbicationId: this.grua.StowageUbicationId,
                        DateFrom: moment(this.DateFrom, "DD/MM/YYYY HH:mm").format('YYYY-MM-DD HH:mm'),
                        DateTo: moment(this.DateTo, "DD/MM/YYYY HH:mm").format('YYYY-MM-DD HH:mm'),
                    }
                }else{
                    valores = {
                        StowagePlanningCraneId: this.formAsignGrua.StowagePlanningCraneId,
                        StowagePlanningId: this.planificacionId,
                        VesselCraneId: this.grua.VesselCraneId,
                        StowageUbicationId: this.grua.StowageUbicationId,
                        DateFrom: moment(this.DateFrom, "DD/MM/YYYY HH:mm").format('YYYY-MM-DD HH:mm'),
                        DateTo: moment(this.DateTo, "DD/MM/YYYY HH:mm").format('YYYY-MM-DD HH:mm'),
                        Status: this.formAsignGrua.FgAct
                    }
                }
            }else if(this.$store.state.planificacionestiba.tabGruaIndex === 1){
                link = "StowagePlanningMachineCrane-insert";
                data = "StowagePlanningMachineJson";
                if(!this.isEdit){
                    valores = {
                        StowagePlanningId:this.planificacionId,
                        MachineId:this.machineGrua.MachineId,
                        DateFrom:moment(this.DateFrom, "DD/MM/YYYY HH:mm").format('YYYY-MM-DD HH:mm'),
                        DateTo:moment(this.DateTo, "DD/MM/YYYY HH:mm").format('YYYY-MM-DD HH:mm'),
                    }
                }else{
                    valores = {
                        StowagePlanningCraneId: this.formAsignGrua.StowagePlanningCraneId,
                        StowagePlanningId: this.planificacionId,
                        MachineId: this.machineGrua.MachineId,
                        DateFrom: moment(this.DateFrom, "DD/MM/YYYY HH:mm").format('YYYY-MM-DD HH:mm'),
                        DateTo: moment(this.DateTo, "DD/MM/YYYY HH:mm").format('YYYY-MM-DD HH:mm'),
                        Status: this.formAsignGrua.FgAct
                    }
                }
            }
            this.$http.ejecutar('POST', link, valores, { root: data })
            .then(response => {
                this.notifySuccess({text: response.data.data[0].Response});
                this.formAsignGrua.TurnJson = response.data.data[0].TurnJson;
                this.Refresh = true;
                this.resetInputs();
                this.isLoading = false;
            }).catch(err => {
                this.isLoading = false;
                this.$notify({
                    group: 'container',
                    title: '¡Error!',
                    text: err,
                    type: "error"
                });
            })
        } catch (error) {
            this.isLoading = false;
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: error,
                type: "error"
            });
        }
    }
    function editTable(item){
        if(this.$store.state.planificacionestiba.tabGruaIndex === 0){
            this.formAsignGrua.StowagePlanningCraneId = item.StowagePlanningCraneId;
            this.formAsignGrua.FgActPlanningCrane = item.FgActPlanningCrane;
        }else if(this.$store.state.planificacionestiba.tabGruaIndex === 1){
            this.formAsignGrua.StowagePlanningCraneId = item.StowagePlanningCraneId;
        };
        this.DateFrom = item.DateFromNew;
        this.DateTo = item.DateToNew;
        this.itemRangeHrs.DateFrom = this.DateFrom;
        this.itemRangeHrs.DateTo = this.DateTo;
        this.formAsignGrua.FgAct=item.Status=='ACTIVO'?1:0;
        this.originStatus = this.formAsignGrua.FgAct;
        this.isEdit = true;
        this.$v.$touch();
    }
    function resetInputs(){
        this.DateFrom = '';
        this.DateTo = '';
        this.formAsignGrua.StowagePlanningCraneId = "";
        this.formAsignGrua.FgAct=0;
        this.isEdit = false;
        this.$v.$reset();
    }
    function getDateRange(index){
        this.currentDate = new Date();
        this.itinerarySelected = this.myDataItinirary[index];
    
        if(this.myDataItinirary[index].Eta!=undefined){
            this.previousDate = DateFormater.formatDateRange(this.myDataItinirary[index].Eta);
            this.ValidPreviousDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.myDataItinirary[index].Eta));
        }
    
        if(this.myDataItinirary[index].Etd!=undefined){  
            this.laterDate = DateFormater.formatDateRange(this.myDataItinirary[index].Etd);
            this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.myDataItinirary[index].Etd));
        }
    
        if(DateFormater.formatDateTimeWithoutSlash(this.currentDate)>DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.myDataItinirary[index].Etd))){
            this.laterDate = this.currentDate;
            this.ValidLaterDate = DateFormater.formatDateTimeWithoutSlash((this.currentDate));
        }
    }
    function validateDateRange(date) {
        return date < this.previousDate || date > this.laterDate;
    }
    //computed
    function tableText(){
        return tableTextTranslatedHelpers(this);
    }
    function formatedItems() {
        let computedItems = this.formAsignGrua.TurnJson?this.formAsignGrua.TurnJson:[];
        
        if(computedItems.length !== 0){
            if(this.$store.state.planificacionestiba.tabGruaIndex === 0){
                computedItems = computedItems.map((item) => Object.assign({}, item, {
                    DateFromNew: item.DateFrom ? moment(item.DateFrom).format("DD/MM/YYYY HH:mm") : 'N/A',
                    DateToNew: item.DateTo ? moment(item.DateTo).format("DD/MM/YYYY HH:mm") : 'N/A',
                    TransaFromDate: item.TransaRegDate ? moment(item.TransaRegDate).format("DD/MM/YYYY HH:mm") : 'N/A',
                    Status: item.FgActPlanningCrane ? 'ACTIVO' : 'INACTIVO',
                    status: item.FgActPlanningCrane,
                    _classes: ( item.FgActPlanningCrane ) ? '' : 'table-danger'
                }));
            }else if(this.$store.state.planificacionestiba.tabGruaIndex === 1){
                computedItems = computedItems.map((item) => Object.assign({}, item, {
                    DateFromNew: item.DateFrom ? moment(item.DateFrom).format("DD/MM/YYYY HH:mm") : 'N/A',
                    DateToNew: item.DateTo ? moment(item.DateTo).format("DD/MM/YYYY HH:mm") : 'N/A',
                    TransaFromDate: item.TransaRegDate ? moment(item.TransaRegDate).format("DD/MM/YYYY HH:mm") : 'N/A',
                    Status: item.FgActPlanningCrane ? 'ACTIVO' : 'INACTIVO',
                    status: item.FgActPlanningCrane,
                    _classes: ( item.FgActPlanningCrane ) ? '' : 'table-danger'
                }));
            }
            
        }

        return computedItems.filter(item => {
            return item
        })
    }

    function selectOptions(){
        return [
            { 
                value: true, 
                label: this.$t('label.ACTIVO')
            },
            { 
                value: false, 
                label: this.$t('label.INACTIVO')
            }
        ];
    }

    //watch
    function modalGruas(newQuestion){
        if(newQuestion){
            if(this.ItineraryId !== 0 || this.ItineraryId !== null){
                const Index = this.myDataItinirary.map(function(e) {
                    return e.ItineraryId; 
                }).indexOf(this.ItineraryId);
                if(Index > -1){
                    this.getDateRange(Index);
                    if(this.$store.state.planificacionestiba.tabGruaIndex === 0){
                        this.formAsignGrua.TurnJson = this.grua.TurnJson? this.grua.TurnJson : [];
                        this.CraneName = this.grua.CraneName;
                    }else if(this.$store.state.planificacionestiba.tabGruaIndex === 1){
                        this.formAsignGrua.TurnJson = this.machineGrua.TurnJson ? this.machineGrua.TurnJson : [];
                        this.CraneName = this.machineGrua.MachineName;
                    }
                }
            }
        }else{
            this.Refresh = false;
            this.resetInputs();
        }
    }

    export default {
        name:'app-modal-gruas',
        data,
        validations(){ return FormAsignGrua(DateFormater.formatDateTimeWithoutSlash(this.DateFrom), DateFormater.formatDateTimeWithoutSlash(this.DateTo), this.ValidLaterDate, this.ValidPreviousDate) },
        mixins: [
            GeneralMixin,
            ModalMixin,
        ],
        methods:{
            statusSelectColor,
            validateDateRange,
            getDateRange,
            registerData,
            cerrarModal,
            editTable,
            resetInputs,
        },
        props: {
            grua: {
                type: Object,
                required: true,
                default: () => {}
            },
            machineGrua: {
                type: Object,
                required: true,
                default: () => {}
            }
        },
        computed:{
            formatedItems,
            tableText,
            selectOptions,
            fields,
            
            ...mapState({
                planificacionId: state => state.planificacionestiba.planificacionId,
                idState: state=> state.planificacionestiba.id,
                myDataItinirary: state => state.planificacionestiba.myDataItinirary,
                ItineraryId: state => state.planificacionestiba.ItineraryId,
                apiState: state => state.planificacionestiba.apiState,
                apiStateForm: state => state.planificacionestiba.apiStateForm,
                modalGruas: state => state.planificacionestiba.modalGruas,
            })
        },
        watch:{
            modalGruas,
            DateFrom: async function(val) {
                try{
                    if(val != ''){
                        if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate && this.itinerarySelected.Eta!=undefined) {
                            throw this.$t('label.ErrorEtaPleaseCheck');
                        }
                        if(DateFormater.formatDateTimeWithoutSlash(this.currentDate)>DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Etd))){
                            if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate) {
                                throw this.$t('label.ErrorCurrentDatedPleaseCheck');
                            }
                        }else if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate && this.itinerarySelected.Etd!=undefined) {
                            throw this.$t('label.ErrorEtdPleaseCheck');
                        }
                        if (DateFormater.formatDateTimeWithoutSlash(val) >= DateFormater.formatDateTimeWithoutSlash(this.DateTo) && this.DateTo!='') {
                            throw this.$t('label.ErrorFromToPleaseCheck');
                        }
                    }
                }catch(e){
                    this.$notify({
                        group: 'container',
                        title: '¡Error!',
                        text: e,
                        type: "error"
                    });
                }
            },
            DateTo: async function(val) {
                try{
                    if(val != ''){
                        if (DateFormater.formatDateTimeWithoutSlash(val) < this.ValidPreviousDate && this.itinerarySelected.Eta!=undefined) {
                            throw this.$t('label.ErrorEtaPleaseCheck');
                        }
                        if(DateFormater.formatDateTimeWithoutSlash(this.currentDate)>DateFormater.formatDateTimeWithoutSlash(DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Etd))){
                            if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate) {
                                throw this.$t('label.ErrorCurrentDatedPleaseCheck');
                            }
                        }else if (DateFormater.formatDateTimeWithoutSlash(val) > this.ValidLaterDate && this.itinerarySelected.Etd!=undefined) {
                            throw this.$t('label.ErrorEtdPleaseCheck');
                        }
                        if (DateFormater.formatDateTimeWithoutSlash(val) <= DateFormater.formatDateTimeWithoutSlash(this.DateFrom) && this.DateFrom!='') {
                            throw this.$t('label.ErrorFromToPleaseCheck');
                        }
                    }
                }catch(e){
                    this.$notify({
                        group: 'container',
                        title: '¡Error!',
                        text: e,
                        type: "error"
                    });
                }
            }
        }
    }
</script>

<style lang="scss">
    .form-especial {
        margin-bottom:0px;
    }
    .botoneria_especial{
        padding:0px;
        margin:0px;
        max-width: 3.5%;
    }
    .sizeGruas .modal-xl{
        min-width: 90%;
    }
    .mx-datepicker{
        width:100%
    }
    .vuedatapickerespecial{
        position: fixed !important;
        .mx-time-column .mx-time-list::after {
            content: "";
            display: none;
            height: 192px;
        }
    }
    
</style>