var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"sm":"12"}},[_c('loading-overlay',{attrs:{"active":_vm.loadingOverlay,"is-full-page":true,"loader":"bars"}}),_c('CRow',[_c('modalInsumo',{attrs:{"modal":_vm.showModal},on:{"cerrarModal":function($event){_vm.showModal=false},"child-refresh":function($event){_vm.refrescarTabla=true}}}),_c('CCol',{staticClass:"text-right mb-2 mt-2",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
              content: _vm.$t('label.supply'),
              placement: 'top-end'
            }),expression:"{\n              content: $t('label.supply'),\n              placement: 'top-end'\n            }"}],attrs:{"square":"","color":"add"},on:{"click":function($event){return _vm.modalSupplie(true)}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nuevo')))])],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"lg","fields":_vm.fields,"items":_vm.items,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"hover":"","sorter":""},scopedSlots:_vm._u([{key:"Detalle",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: _vm.$t('label.edit')+_vm.$t('label.supply'),
                      placement: 'top',
                    }),expression:"{\n                      content: $t('label.edit')+$t('label.supply'),\n                      placement: 'top',\n                    }"}],staticClass:"mr-1",attrs:{"size":"sm","color":"edit"},on:{"click":function($event){return _vm.modalSupplie(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                      content: _vm.$t('label.delete')+_vm.$t('label.supplie'),
                      placement: 'top-end',
                    }),expression:"{\n                      content: $t('label.delete')+$t('label.supplie'),\n                      placement: 'top-end',\n                    }"}],staticClass:"mx-1",attrs:{"size":"sm","color":"wipe"},on:{"click":function($event){return _vm.ConfirmarEliminado(item)}}},[_c('CIcon',{attrs:{"name":"cil-trash"}})],1)],1)]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }