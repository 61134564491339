<template>
    <form>
        <loading-overlay :active="apiStateFormLoading" :is-full-page="true" loader="bars" />
        <CModalExtended
            :title="titleModal"
            color="dark"
            size="xl"
            :show.sync="modalActive"
            @update:show="closeModal(false, false)"
            :closeOnBackdrop="false"
        >   
            <CRow class="mx-2">
                <CCol sm="12" lg="6">
                            <!-- @input="formMachine.MachineConditionId = $event.target.value"
                            @blur="$v.formMachine.MachineConditionId.$touch()"
                            -->
                    <CRow>
                        <CCol sm="12">
                            <CSelect
                                :label="$t('label.machineCondition')"
                                addLabelClasses="required text-right"
                                size="sm"
                                :horizontal="{label:'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                                :value="formMachine.MachineConditionId"
                                :addInputClasses="{ 'is-invalid': $v.formMachine.MachineConditionId.$error }"
                                :options="optionListCondition"
                                @change="conditionChange($event.target.value)"                    
                                :is-valid="hasError($v.formMachine.MachineConditionId)" 
                                :invalid-feedback="errorMessage($v.formMachine.MachineConditionId)"                
                                @blur="$v.formMachine.MachineConditionId.$touch()"                    
                            >
                            </CSelect>
                        </CCol><!-- verificarAlquilada -->
                        <CCol sm="12" v-if="vSuplier">
                            <CSelect
                                :label="$t('label.machineSupplier')"
                                addLabelClasses="required text-right"
                                size="sm"
                                :horizontal="{label:'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                                :value="formMachine.ClientTpId"
                                :addInputClasses="{ 'is-invalid': $v.formMachine.ClientTpId.$error }"
                                @blur="$v.formMachine.ClientTpId.$touch()"
                                @change="formMachine.ClientTpId = $event.target.value"
                                :options="optionListMachineSuplier"
                                :is-valid="hasError($v.formMachine.ClientTpId)"  
                                :invalid-feedback="errorMessage($v.formMachine.ClientTpId)" 
                            >
                            </CSelect>
                        </CCol>

                        <CCol sm="12">
                            <CInput
                                :label="$t('label.machineName')"
                                addLabelClasses="required text-right"
                                size="sm"
                                :placeholder="$t('label.machineName')"
                                :horizontal="{label:'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                                v-uppercase
                                v-model="$v.formMachine.MachineName.$model"
                                @blur="$v.formMachine.MachineName.$touch()"
                                :addInputClasses="{ 'is-invalid': $v.formMachine.MachineName.$error }"
                                :invalid-feedback="errorMessage($v.formMachine.MachineName)"
                                required 
                                :is-valid="hasError($v.formMachine.MachineName)" 
                                maxlength="250"  
                                >
                            </CInput>
                        </CCol>
                        <CCol sm="12">
                            <CSelect
                                :label="$t('label.machineType')"
                                addLabelClasses="required text-right"
                                size="sm"
                                :horizontal="{label:'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                                :value="formMachine.TpMachineId"
                                :addInputClasses="{ 'is-invalid': $v.formMachine.TpMachineId.$error }"
                                @blur="$v.formMachine.TpMachineId.$touch()"
                                @change="formMachine.TpMachineId = $event.target.value"
                                :options="optionListType"
                                :is-valid="hasError($v.formMachine.TpMachineId)" 
                                :invalid-feedback="errorMessage($v.formMachine.TpMachineId)"
                            >
                            </CSelect>
                        </CCol>
                        <CCol sm="12">
                            <CSelect
                                :label="$t('label.brand')"
                                addLabelClasses="required text-right"
                                size="sm"
                                :horizontal="{label:'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                                :value="formMachine.MarcaId"
                                :addInputClasses="{ 'is-invalid': $v.formMachine.MarcaId.$error }"
                                @blur="$v.formMachine.MarcaId.$touch()"
                                @change="changeBranch($event.target.value)"
                                :options="optionListBranch"
                                :is-valid="hasError($v.formMachine.MarcaId)" 
                                :invalid-feedback="errorMessage($v.formMachine.MarcaId)"
                            >
                            </CSelect>
                        </CCol>
                        <CCol sm="12">
                            <CSelect
                                :label="$t('label.model')"
                                addLabelClasses="required text-right"
                                size="sm"
                                :horizontal="{label:'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                                :value.sync="formMachine.ModelId"
                                :addInputClasses="{ 'is-invalid': $v.formMachine.ModelId.$error }"
                                @blur="$v.formMachine.ModelId.$touch()"
                                v-model="formMachine.ModelId"
                                :options="optionListModel"
                                :is-valid="hasError($v.formMachine.ModelId)" 
                                :invalid-feedback="errorMessage($v.formMachine.ModelId)"
                            >
                            </CSelect>
                        </CCol>
                        <CCol sm="12">
                            <CInput
                                :label="$t('label.serial')"
                                size="sm"
                                :placeholder="$t('label.serial')"
                                :horizontal="{label:'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                                v-uppercase
                                v-model="$v.formMachine.Serial.$model"
                                @blur="$v.formMachine.Serial.$touch()"
                                addLabelClasses="text-right"
                                :addInputClasses="{ 'is-invalid': $v.formMachine.Serial.$error }"
                                :invalid-feedback="errorMessage($v.formMachine.Serial)" 
                                :is-valid="hasError($v.formMachine.Serial)" 
                                maxlength="50"
                                >
                            </CInput>
                        </CCol>
                        <CCol sm="12">
                            <CSelect
                                :label="$t('label.state')"
                                addLabelClasses="required text-right"
                                size="sm"
                                :horizontal="{label:'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                                :value="formMachine.ProcessStatusId"
                                :addInputClasses="{ 'is-invalid': $v.formMachine.ProcessStatusId.$error }"
                                @blur="$v.formMachine.ProcessStatusId.$touch()"
                                @change="formMachine.ProcessStatusId = $event.target.value"
                                :options="optionListEstado"
                                :is-valid="hasError($v.formMachine.ProcessStatusId)"
                                :invalid-feedback="errorMessage($v.formMachine.ProcessStatusId)"
                            >
                            </CSelect>
                        </CCol>

                    </CRow>
                </CCol>
                <CCol sm="12" lg="6">
                    <CRow>   
                        <CCol sm="12">
                            <CInput
                                :label="$t('label.code')"
                                :placeholder="$t('label.code')"
                                size="sm"
                                :horizontal="{label:'col-sm-12 col-lg-6', input: 'col-sm-12 col-lg-6'}"
                                v-uppercase
                                v-model="$v.formMachine.Cod.$model"
                                @blur="$v.formMachine.Cod.$touch()"
                                addLabelClasses="required text-right"
                                :addInputClasses="{ 'is-invalid': $v.formMachine.Cod.$error }"
                                :invalid-feedback="errorMessage($v.formMachine.Cod)"
                                required 
                                :is-valid="hasError($v.formMachine.Cod)" 
                                maxlength="10"
                                >
                            </CInput>
                        </CCol>
                        <CCol sm="12">
                            <CSelect
                                :label="$t('label.year')"
                                addLabelClasses="text-right"
                                size="sm"
                                :horizontal="{label:'col-sm-12 col-lg-6', input: 'col-sm-12 col-lg-6'}"
                                :value="formMachine.Year"
                                :addInputClasses="{ 'is-invalid': $v.formMachine.Year.$error }"
                                @blur="$v.formMachine.Year.$touch()"
                                @change="formMachine.Year = $event.target.value"
                                :options="optionListYear"
                                :is-valid="hasError($v.formMachine.Year)" 
                                :invalid-feedback="errorMessage($v.formMachine.Year)"
                            >
                            </CSelect>
                        </CCol>
                        <CCol sm="12">
                            <!--CInput
                                :label="$t('label.maxLoadCapacity')"
                                :horizontal="{label:'col-sm-6', input: 'col-sm-6'}"
                                append="t"
                                addLabelClasses="text-right"
                                v-model="$v.formMachine.MaxCapacity.$model"
                                @keyup="$v.formMachine.MaxCapacity.$touch()"
                                :addInputClasses="{ 'is-invalid': $v.formMachine.MaxCapacity.$error }"
                                :is-valid="hasError($v.formMachine.MaxCapacity)"  
                                type="number"
                                step=".01"
                                min="0"
                                max="99999.99"
                            >
                            </CInput-->
                            <div class="form-group form-row mb-2">
                                <label class="text-right col-sm-12 col-lg-6 col-form-label-sm">{{ $t('label.maxLoadCapacity') }}</label>
                                <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                    <money
                                        v-model.trim="$v.formMachine.MaxCapacity.$model"
                                        @blur="$v.formMachine.MaxCapacity.$touch()"
                                        v-bind="measureMaxCapacity"
                                        :class="computedMaxCapacity 
                                            ? 'form-control is-valid' 
                                            : (verifyMaxCapacity ? 'form-control is-invalid' : 'form-control') 
                                        "
                                    >
                                    </money>
                                    <div class="input-group-append append-sm">
                                        <span class="input-group-text">t</span>
                                    </div>
                                </div>
                                <div class=" col-sm-11 col-lg-6" v-if="$v.formMachine.MaxCapacity.$error"></div>
                                <div class="col-sm-11 col-lg-6 text-invalid-feedback" 
                                    v-if="$v.formMachine.MaxCapacity.$error && !$v.formMachine.MaxCapacity.maxValue"
                                >
                                    {{$t('label.maxValueIs') }} {{$v.formMachine.MaxCapacity.$params.maxValue.max}}
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="12">
                            <!--CInput
                                :label="$t('label.liftingSpeed')"
                                addLabelClasses="text-right"
                                :horizontal="{label:'col-sm-6', input: 'col-sm-6'}"
                                v-model="$v.formMachine.SpeedCharge.$model"
                                @keyup="$v.formMachine.SpeedCharge.$touch()"
                                :addInputClasses="{ 'is-invalid':  $v.formMachine.SpeedCharge.$error }"
                                :is-valid="hasError($v.formMachine.SpeedCharge)"    
                                type="number"
                                step=".01"
                                min="0"
                                max="999.99"  
                            >
                                <template #append>
                                    <CSelect
                                        :value.sync="formMachine.UnitMeasureIdSC"
                                        @blur="$v.formMachine.UnitMeasureIdSC.$touch()"
                                        v-model="formMachine.UnitMeasureIdSC"
                                        :options="optionListUnityMesure"
                                        :addInputClasses=" {'is-invalid': $v.formMachine.UnitMeasureIdSC.$error }"
                                        class="m-0"
                                        :is-valid="hasError($v.formMachine.UnitMeasureIdSC)"  
                                    />
                                </template>
                                <template #invalid-feedback>
                                    <div v-if="$v.formMachine.SpeedDischarge.$error">
                                        <div class='text-invalid-feedback ' v-if="!$v.formMachine.SpeedCharge.required">{{$t('label.required')}}</div>
                                        <div class='text-invalid-feedback ' v-if="!$v.formMachine.SpeedCharge.decimal">{{$t('label.onlyDecimal')}}</div>
                                        <div class='text-invalid-feedback ' v-if="!$v.formMachine.SpeedCharge.minValue">{{$t('label.minValueIs') }} {{ $v.formMachine.SpeedCharge.$params.minValue.min}}</div>
                                        <div class='text-invalid-feedback ' v-if="!$v.formMachine.SpeedCharge.maxValue">{{$t('label.maxValueIs') }} {{$v.formMachine.SpeedCharge.$params.maxValue.max}}</div>
                                    </div>
                                    <div v-if="$v.formMachine.UnitMeasureIdSC.$error">
                                        <div class='text-invalid-feedback ' v-if="!$v.formMachine.UnitMeasureIdSC.required">{{$t('label.requiresUm')}}</div>
                                    </div>
                                </template>
                            </CInput-->
                            <div class="form-group form-row mb-2">
                                <label class="text-right col-sm-12 col-lg-6 col-form-label-sm">{{ $t('label.liftingSpeed') }}</label>
                                <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                    <money
                                        v-model.trim="$v.formMachine.SpeedCharge.$model"
                                        v-bind="measure"
                                        :class="computedSpeedCharge 
                                            ? 'form-control is-valid' 
                                            : (verifySpeedCharge ? 'form-control is-invalid' : 'form-control') 
                                        "
                                    >
                                    </money>
                                    <div class="input-group-append">
                                        <CSelect
                                            size="sm"
                                            :value.sync="formMachine.UnitMeasureIdSC"
                                            @blur="$v.formMachine.UnitMeasureIdSC.$touch()"
                                            v-model="formMachine.UnitMeasureIdSC"
                                            :options="optionListUnityMesure"
                                            :addInputClasses=" {'is-invalid': $v.formMachine.UnitMeasureIdSC.$error }"
                                            class="m-0"
                                            :is-valid="hasError($v.formMachine.UnitMeasureIdSC)"   
                                        />
                                    </div>
                                    
                                </div>
                                <div class=" col-sm-11 col-lg-6" v-if="$v.formMachine.SpeedCharge.$error"></div>
                                <div class="col-sm-11 col-lg-6 text-invalid-feedback" 
                                    v-if="$v.formMachine.SpeedCharge.$error && !$v.formMachine.SpeedCharge.maxValue"
                                >
                                    {{$t('label.maxValueIs') }} {{$v.formMachine.SpeedCharge.$params.maxValue.max}}
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="12">
                            <!--CInput
                                :label="$t('label.loweringSpeed')"
                                addLabelClasses="text-right"
                                :horizontal="{label:'col-sm-6', input: 'col-sm-6'}"
                                v-model="$v.formMachine.SpeedDischarge.$model"
                                @keyup="$v.formMachine.SpeedDischarge.$touch()"
                                :addInputClasses="{ 'is-invalid': ( $v.formMachine.SpeedDischarge.$error  ) }" 
                                :is-valid="hasError($v.formMachine.SpeedDischarge)"                        
                            type="number"
                                step=".01"
                                min="0"  
                                max="999.99" 
                            >
                                <template #append>
                                    <CSelect
                                        :value.sync="formMachine.UnitMeasureIdSD"
                                        @blur="$v.formMachine.UnitMeasureIdSD.$touch()"
                                        v-model="formMachine.UnitMeasureIdSD"
                                        :options="optionListUnityMesure"
                                        :addInputClasses="{ 'is-invalid': (  $v.formMachine.UnitMeasureIdSD.$error ) }"
                                        class="m-0"
                                        :is-valid="hasError($v.formMachine.UnitMeasureIdSD)"  
                                    />
                                </template>
                                <template #invalid-feedback>
                                    <div v-if="$v.formMachine.SpeedDischarge.$error">
                                        <div class='text-invalid-feedback ' v-if="!$v.formMachine.SpeedDischarge.required">{{$t('label.required')}}</div>
                                        <div class='text-invalid-feedback ' v-if="!$v.formMachine.SpeedDischarge.decimal">{{$t('label.onlyDecimal')}}</div>
                                        <div class='text-invalid-feedback ' v-if="!$v.formMachine.SpeedDischarge.minValue">{{$t('label.minValueIs') }} {{ $v.formMachine.SpeedDischarge.$params.minValue.min}}</div>
                                        <div class='text-invalid-feedback ' v-if="!$v.formMachine.SpeedDischarge.maxValue">{{$t('label.maxValueIs') }} {{$v.formMachine.SpeedDischarge.$params.maxValue.max}}</div>
                                    </div>
                                    <div v-if="$v.formMachine.UnitMeasureIdSD.$error">
                                        <div class='text-invalid-feedback ' v-if="!$v.formMachine.UnitMeasureIdSD.required">{{$t('label.requiresUm')}}</div>
                                    </div>
                                </template>
                            </CInput-->
                            <div class="form-group form-row mb-2">
                                <label class="text-right col-sm-12 col-lg-6 col-form-label-sm">{{ $t('label.loweringSpeed') }}</label>
                                <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                    <money
                                        v-model.trim="$v.formMachine.SpeedDischarge.$model"
                                        v-bind="measure"
                                        :class="computedSpeedDischarge 
                                            ? 'form-control is-valid' 
                                            : (verifySpeedDischarge ? 'form-control is-invalid' : 'form-control') 
                                        "
                                    >
                                    </money>
                                    <div class="input-group-append">
                                        <CSelect
                                            size="sm"
                                            :value.sync="formMachine.UnitMeasureIdSD"
                                            @blur="$v.formMachine.UnitMeasureIdSD.$touch()"
                                            v-model="formMachine.UnitMeasureIdSD"
                                            :options="optionListUnityMesure"
                                            :addInputClasses=" {'is-invalid': $v.formMachine.UnitMeasureIdSD.$error }"
                                            class="m-0"
                                            :is-valid="hasError($v.formMachine.UnitMeasureIdSD)"   
                                        />
                                    </div>
                                    
                                </div>
                                <div class=" col-sm-11 col-lg-6" v-if="$v.formMachine.SpeedDischarge.$error"></div>
                                <div class="col-sm-11 col-lg-6 text-invalid-feedback" 
                                    v-if="$v.formMachine.SpeedDischarge.$error && !$v.formMachine.SpeedDischarge.maxValue"
                                >
                                    {{$t('label.maxValueIs') }} {{$v.formMachine.SpeedDischarge.$params.maxValue.max}}
                                </div>
                            </div>
                        </CCol>
                        <CCol sm="12">
                            <!--CInput
                                :label="$t('label.maximumRadio')"
                                addLabelClasses="text-right"
                                :horizontal="{label:'col-sm-6', input: 'col-sm-6'}"
                                append="m"
                                v-model="$v.formMachine.MaxRadio.$model"
                                @blur="$v.formMachine.MaxRadio.$touch()"
                                :invalid-feedback="errorMessage($v.formMachine.MaxRadio)"
                                :is-valid="hasError($v.formMachine.MaxRadio)" 
                                type="number"
                                step=".01"
                                min="0"
                                max="999.99"
                            >
        
                            </CInput-->
                            <div class="form-group form-row mb-2">
                                <label class="text-right col-sm-12 col-lg-6 col-form-label-sm">{{ $t('label.maximumRadio') }}</label>
                                <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                                    <money
                                        v-model.trim="$v.formMachine.MaxRadio.$model"
                                        @blur="$v.formMachine.MaxRadio.$touch()"
                                        v-bind="measure"
                                        :class="computedMaxRadio 
                                            ? 'form-control is-valid' 
                                            : (verifyMaxRadio ? 'form-control is-invalid' : 'form-control') 
                                        "
                                    >
                                    </money>
                                    <div class="input-group-append append-sm">
                                        <span class="input-group-text">M</span>
                                    </div>
                                </div>
                                <div class="col-sm-11 col-lg-6" v-if="$v.formMachine.MaxRadio.$error"></div>
                                <div class="col-sm-11 col-lg-6 text-invalid-feedback" 
                                    v-if="$v.formMachine.MaxRadio.$error && !$v.formMachine.MaxRadio.maxValue"
                                >
                                    {{$t('label.maxValueIs') }} {{$v.formMachine.MaxRadio.$params.maxValue.max}}
                                </div>
                            </div>
                        </CCol>

                        <CCol sm="12">
                            <CSelect
                                :label="$t('label.loadDischargeOperations')"
                                addLabelClasses="required text-right"
                                size="sm"
                                :horizontal="{label:'col-sm-12 col-lg-6', input: 'col-sm-12 col-lg-6'}"
                                v-model="formMachine.FgOperation"
                                :value.sync="formMachine.FgOperation"
                                :options="selectOptionsDescarga"
                                :is-valid="hasError($v.formMachine.FgOperation)" 
                                :invalid-feedback="errorMessage($v.formMachine.FgOperation)"
                                @blur="$v.formMachine.FgOperation.$touch()"
                            />
                        </CCol>
                        <CCol sm="12">
                            <CSelect
                                :label="$t('label.status')"
                                addLabelClasses="required text-right"
                                size="sm"
                                :horizontal="{label:'col-sm-12 col-lg-6', input: 'col-sm-12 col-lg-6'}"
                                :is-valid="statusSelectColor"
                                v-if="idIndentification"
                                v-model="formMachine.FgActMachine"
                                :value.sync="formMachine.FgActMachine"
                                :options="selectOptions"
                            />
                        </CCol>
                    </CRow>
                    
                </CCol>
            </CRow>
            <template #footer>
                <CButton
                    color="add"
                    class="m-2"
                    @click="registerData"
                    :disabled="apiStateFormLoading"
                >
                    <div>
                        <CIcon name="checkAlt"/>&nbsp;
                        {{$t('button.accept')}}
                    </div>
                </CButton>
                <CButton
                    color="wipe"
                    @click="closeModal(false, false)"
                    class="m-2"
                    :disabled="apiStateFormLoading"
                >
                <CIcon name="x"/>&nbsp;
                    {{$t('button.cancel')}}
                </CButton>
            </template>
        </CModalExtended>
    </form>
</template>
<style>
    .not-border{
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
</style>
<script>
    import ENUM from '@/_store/enum';
    import { FormMachine } from '@/_validations/maquina/MaquinaValidation';
    import { mapState } from 'vuex';
    import ModalMixin from '@/_mixins/modal';
    import UpperCase  from '@/_validations/uppercase-directive';
    import NumberInput from '@/components/numberinput';
    import {alertPropertiesHelpers} from '@/_helpers/funciones'
    import { errorMessage } from '@/_helpers/funciones';
    import { hasError } from '@/_helpers/funciones';
    import { VMoney, Money } from "v-money";

    //data
    function data() {
        return {
            modalActive: false,
            vSuplier: false,
            InformationEdit: {},
            formMachine:{
                MachineId:0,
                TpMachineId:'',
                MachineConditionId:'',
                MarcaId:'',
                ModelId:'',
                MachineName:'',
                ProcessStatusId:'',
                ClientTpId:'',
                Year:2021,
                Cod:'',
                Serial:'',
                MaxCapacity:0,
                MaxRadio:0,
                UnitMeasureIdSC:'',
                UnitMeasureIdSD:'',
                SpeedCharge:0,
                SpeedDischarge:0,
                FgOperation:true,
                FgActMachine:true,
                
            },
            MarcaId:'',
            myDataBrand:[],
            myDataModel:[],
            myDataStatus:[],
            myDataUnityMesure:[],
            myDataMachineSuplier:[],
            originalAct: '',
            myDataMachineTp:[],
            myDataMachineCondition:[],
            measure: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false,
                maxlength: 6
            },
            measureMaxCapacity: {
                decimal: ",",
                thousands: ".",
                prefix: "",
                suffix: "",
                precision: 2,
                masked: false,
                maxlength: 9
            },
            verifySpeedCharge: false,
            verifySpeedDischarge: false,
            verifyMaxCapacity: false,
            verifyMaxRadio: false,
        }
    }

    //methods
    function closeModal(filtre, Refresh){
        this.$emit('set-list', filtre, Refresh);
    }
    function registerData(){
        try {
            this.verifySpeedCharge = true;
            this.verifySpeedDischarge = true;
            this.verifyMaxCapacity = true;
            this.verifyMaxRadio = true;
            this.$v.formMachine.$touch();
            if (this.$v.formMachine.$pending || this.$v.formMachine.$error){
                throw this.$t('label.errorsPleaseCheck');
            }

            let { 
                MachineId,TpMachineId,MachineConditionId,ModelId,MachineName,ProcessStatusId,ClientTpId,
                Year,Cod,Serial,MaxCapacity,MaxRadio,UnitMeasureIdSC,UnitMeasureIdSD,SpeedCharge,SpeedDischarge,FgActMachine,FgOperation
                } = this.formMachine;
            const { dispatch } = this.$store;

            if(this.originalAct !== '' && MachineId !== 0){
                if(this.originalAct !== FgActMachine){
                    this.$swal.fire({
                            text: `${this.$t('label.changeStatusQuestion')}?`,
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonColor: '#42AA91',
                            cancelButtonColor: '#E1373F',
                            confirmButtonText: this.$t('button.confirm'),
                            cancelButtonText: this.$t('button.cancel')
                        }).then((result) => {
                        if (result.isConfirmed) {
                            this.closeModal(false, true);
                            dispatch('maquina/sendDataMachine', { CompanyBranchId:this.branch.CompanyBranchId,MachineId,TpMachineId,MachineConditionId,ModelId,MachineName,ProcessStatusId,ClientTpId,
                                Year,Cod,Serial,MaxCapacity,MaxRadio,UnitMeasureIdSC,UnitMeasureIdSD,SpeedCharge,SpeedDischarge,FgActMachine,FgOperation });
                        }else {
                        //	this.$swal('Operación cancelada');
                        }
                    })
                }else{
                    this.closeModal(false, true);
                    dispatch('maquina/sendDataMachine', { CompanyBranchId:this.branch.CompanyBranchId,MachineId,TpMachineId,MachineConditionId,ModelId,MachineName,ProcessStatusId,ClientTpId,
                        Year,Cod,Serial,MaxCapacity,MaxRadio,UnitMeasureIdSC,UnitMeasureIdSD,SpeedCharge,SpeedDischarge,FgActMachine,FgOperation });
                }
            }else{
                this.closeModal(false, true);
                dispatch('maquina/sendDataMachine', { CompanyBranchId:this.branch.CompanyBranchId,MachineId,TpMachineId,MachineConditionId,ModelId,MachineName,ProcessStatusId,ClientTpId,
                    Year,Cod,Serial,MaxCapacity,MaxRadio,UnitMeasureIdSC,UnitMeasureIdSD,SpeedCharge,SpeedDischarge,FgActMachine,FgOperation });
            }
        } catch (error) {
            this.$notify({
                group: 'container',
                title: '¡Error!',
                text: error,
                type: "error"
            });
        }
    }
    function changeBranch(value){
        this.formMachine.MarcaId = value;
        if(value !== ''){
            this.getDataModel();
        }   
    }
    function getDataModel(){
        this.$store.state.maquina.apiStateForm = ENUM.LOADING;
        this.$http.ejecutar('GET', 'Model-list', { BrandId:this.formMachine.MarcaId,Filter: 'ACTIVO' }, '').then(response => {
            if(response.status === 200){
                this.myDataModel = response.data.data;
                this.$store.state.maquina.apiStateForm = ENUM.INIT;
                /*if(this.formPhotoMachine.MachineId !== ''){
                    this.$store.dispatch('maquina/getDocMachinelist',{ MachineId:this.formPhotoMachine.MachineId });
                }*/
            }else{
                this.$store.state.maquina.apiStateForm = ENUM.ERROR;
            }
        }).catch(err => {
            this.$store.state.maquina.apiState = ENUM.ERROR;
            this.$store.commit('maquina/messageMutation', err);
        });
    }
    function getData(){
        this.$store.state.maquina.apiStateForm = ENUM.LOADING;
        try {
            this.$http.ejecutar('GET', 'MachineStatus-list', { Filter: 'ALL' }, '').then(responseMachineStatus => {
                if(responseMachineStatus.status === 200){
                    this.$http.ejecutar('GET', 'MachineSuplier-list', { Filter: 'ACTIVO' }, '').then(responseMachineSuplier => {
                        if(responseMachineSuplier.status === 200){
                            this.$http.ejecutar('GET', 'BrandMachine-list', { Filter: 'ACTIVO' }, '').then(responseBrand => {
                                if(responseBrand.status === 200){
                                    this.$http.ejecutar('GET', 'UnitMeasureByTpUnitMeasureId', { TpUnitMeasureId:'ec73eb11-b431-4594-94c8-0f6183722b76',Filter: 'ACTIVO' }, '').then(response => {
                                        if(response.status === 200){
                                            this.myDataBrand = responseBrand.data.data;
                                            this.myDataUnityMesure = response.data.data;
                                            this.myDataMachineSuplier = responseMachineSuplier.data.data;
                                            this.myDataStatus = responseMachineStatus.data.data;
                                            if(this.formMachine.MachineId !== 0){                                                
                                                this.getDataModel();
                                            }else{
                                                this.formMachine.UnitMeasureIdSC=this.myDataUnityMesure[0].UnitMeasureId;
                                                this.formMachine.UnitMeasureIdSD=this.myDataUnityMesure[0].UnitMeasureId;
                                                this.$store.state.maquina.apiStateForm = ENUM.INIT;
                                            }
                                        }else{
                                            this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                                        }
                                    }).catch(err => {
                                        this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                                        this.$store.commit('maquina/messageMutation', err);
                                    });
                                }else{
                                    this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                                }
                            }).catch(err => {
                                this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                                this.$store.commit('maquina/messageMutation', err);
                            });
                        }else{
                            this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                        }
                    }).catch(err => {
                        this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                        this.$store.commit('maquina/messageMutation', err);
                    });
                }else{
                    this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                }
            }).catch(err => {
                this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                this.$store.commit('maquina/messageMutation', err);
            });
            
            
        } catch (error) {
            this.$store.state.maquina.apiState = ENUM.ERROR;
            this.$store.commit('maquina/messageMutation', error);
        }
        
    } 
    //computed
    function computedMaxCapacity() { 
        if (this.formMachine.MaxCapacity < 0) {
            return false;
        } else if (this.formMachine.MaxCapacity == 0 && !this.verifyMaxCapacity) {
            return false;
        } else if (this.formMachine.MaxCapacity > 100000) {
            this.verifyMaxCapacity = true;
            return false;
        } else {
            return true;
        }
    }
    function computedSpeedCharge() { 
        if (this.formMachine.SpeedCharge < 0) {
            return false;
        } else if (this.formMachine.SpeedCharge == 0 && !this.verifySpeedCharge) {
            return false;
        } else if (this.formMachine.SpeedCharge > 1000) {
            this.verifySpeedCharge = true;
            return false;
        } else {
            return true;
        }
    }
    function computedSpeedDischarge () { 
        if (this.formMachine.SpeedDischarge  < 0) {
            return false;
        } else if (this.formMachine.SpeedDischarge  == 0 && !this.verifySpeedDischarge ) {
            return false;
        } else if (this.formMachine.SpeedDischarge  > 1000) {
            this.verifySpeedDischarge  = true;
            return false;
        } else {
            return true;
        }
    }
    function computedMaxRadio() { 
        if (this.formMachine.MaxRadio < 0) {
            return false;
        } else if (this.formMachine.MaxRadio == 0 && !this.verifyMaxRadio) {
            return false;
        } else if (this.formMachine.MaxRadio > 100000) {
            this.verifyMaxRadio = true;
            return false;
        } else {
            return true;
        }
    }
    function idIndentification(){
        return this.formMachine.MachineId !== 0;
    }
    /*
    function verificarAlquilada(){
        if(this.formMachine.MachineConditionId === ''){
            return false;
        }else{
            const Index = this.myDataMachineCondition.map(function(e) {
                return e.MachineConditionId; 
            }).indexOf(this.formMachine.MachineConditionId);
            if(Index !== -1){
                if(Index === 0 || Index === 1){
                    return true;
                }
            }else return false;
        }
    }*/
    function optionListYear(){
        const chart = [];
        var fecha = new Date();
        var ano = fecha.getFullYear();
        let n = 1950;
        while (n < ano) {
            n ++;
            chart.push(n)
        }
        return chart;
    }
    function optionListUnityMesure(){
        if(this.myDataUnityMesure.length === 0){
            return [{
                value: '', 
                label: "*",
            }];
        }else{
            if(this.formMachine.UnitMeasureIdSC == '')
                this.formMachine.UnitMeasureIdSC= this.myDataUnityMesure[0].UnitMeasureId;
            if(this.formMachine.UnitMeasureIdSD == '')
                this.formMachine.UnitMeasureIdSD= this.myDataUnityMesure[0].UnitMeasureId;

            let chart = [];
            this.myDataUnityMesure.map(function(e){
                if(e.FgActUnitMeasure){
                    chart.push({
                        value: e.UnitMeasureId, 
                        label: e.UnitMeasureAbbrev,
                    })
                }
            })
            return chart;
        }
    }
    function optionListEstado(){
        if(this.myDataStatus.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataStatus.map(function(e){
                chart.push({
                    value: e.ProcessStatusId, 
                    label: e.ValueDs,
                })
            })
            return chart;
        }
    }
    function optionListMachineSuplier(){
        if(this.myDataMachineSuplier.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataMachineSuplier.map(function(e){
                if(e.FgActClientTp){
                    chart.push({
                        value: e.ClientTpId, 
                        label: e.ClientName,
                    })
                }
            })
            return chart;
        }
    }
    function optionListBranch(){
        if(this.myDataBrand.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataBrand.map(function(e){
                if(e.FgActBrand){
                    chart.push({
                        value: e.BrandId, 
                        label: e.BrandName,
                    })
                }
            })
            return chart;
        }
    }
    function optionListModel(){
        if(this.myDataModel.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataModel.map(function(e){
                if(e.FgActModel){
                    chart.push({
                        value: e.ModelId, 
                        label: e.ModelName,
                    })
                }
            })
            return chart;
        }
    }
    function checkNumeros($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 188) {
            $event.preventDefault();
        }
    }

    function optionListCondition(){
        if(this.myDataMachineCondition.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataMachineCondition.map(function(e){
                if(e.FgActMachineCondition){
                    chart.push({
                        value: e.MachineConditionId, 
                        label: e.MachineConditionName,
                    })
                }
            })
            return chart;
        }
    }
    function optionListType(){
        if(this.myDataMachineTp.length === 0){
            return [{
                value: '', 
                label: this.$t('label.select'),
            }];
        }else{
            var chart = [{
                value: '', 
                label: this.$t('label.select'),
            }]
            this.myDataMachineTp.map(function(e){
                if(e.FgActTpMachine){
                    chart.push({
                        value: e.TpMachineId, 
                        label: e.TpMachineName,
                    })
                }
            })
            return chart;
        }
    }
    function apiStateFormLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    function statusSelectColor() {
        return this.formMachine.FgActMachine == true;
    }
    //watch
    function modalCraneMachine(newQuestion){
        this.modalActive = newQuestion;
        if(newQuestion === false){
            this.InformationEdit={};
            this.formMachine.MachineId=0;
            this.formMachine.TpMachineId='';
            this.formMachine.MachineConditionId='';
            this.formMachine.MarcaId = '';
            this.formMachine.ModelId='';
            this.formMachine.MachineName='';
            this.formMachine.ProcessStatusId='';
            this.formMachine.ClientTpId='';
            this.formMachine.Year=0;
            this.formMachine.Cod='';
            this.formMachine.Serial='';
            this.formMachine.MaxCapacity=0;
            this.formMachine.MaxRadio=0;
            this.formMachine.UnitMeasureIdSC='';
            this.formMachine.UnitMeasureIdSD='';
            this.formMachine.SpeedCharge=0;
            this.formMachine.SpeedDischarge=0;
            this.formMachine.FgActMachine=true;
            this.vSuplier= false,
            this.originalAct = '';
            this.verifySpeedCharge = false;
            this.verifySpeedDischarge = false;
            this.verifyMaxCapacity = false;
            this.verifyMaxRadio =  false;
            this.$nextTick(() => { this.$v.$reset() })
        }else{
            this.$store.state.maquina.apiStateForm = ENUM.LOADING
            let route = this.Crane ? 'TpMachineCrane-list' : 'TpMachineNotCrane-list';
            this.$http.ejecutar('GET', route, { Filter: 'ACTIVO' }, '')
            .then(responseTpMachine => {
                this.myDataMachineTp = responseTpMachine.data.data;
                this.$http.ejecutar('GET', 'MachineCondition-list', { Filter: 'ACTIVO' }, '')
                .then(responseCondicion => {
                    this.myDataMachineCondition = responseCondicion.data.data;
                    this.$store.state.maquina.apiStateForm = ENUM.INIT;
                    if(this.idState === 0){
                        this.getData();
                    }else{
                        const id = this.idState;
                        this.$store.state.maquina.apiStateForm = ENUM.LOADING;
                        this.$http.ejecutar('GET', 'Machine-by-id',{ MachineId: id }, '' ).then(response => {
                            try {
                                if(response.status === 200){
                                    const Information = response.data.data;
                                    this.InformationEdit = Information[0];
                                    if(Information.length !== 0){
                                        this.$store.state.maquina.idState = 0;
                                        
                                        this.formMachine.MachineId=Information[0].MachineId;
                                        this.formMachine.TpMachineId=Information[0].TpMachineId;
                                        this.formMachine.MachineConditionId=Information[0].MachineConditionId;
                                        this.formMachine.MarcaId = Information[0].BrandId;
                                        this.formMachine.ModelId= Information[0].ModelId;
                                        this.formMachine.MachineName= Information[0].MachineName;
                                        this.formMachine.ProcessStatusId= Information[0].ProcessStatusId;
                                        this.formMachine.ClientTpId=Information[0].ClientTpId ? Information[0].ClientTpId : '';
                                        this.formMachine.Year=Information[0].Year;
                                        this.formMachine.Cod=Information[0].Cod;
                                        this.formMachine.Serial=Information[0].Serial;
                                        this.formMachine.MaxCapacity=Information[0].MaxCapacity ? parseFloat(Information[0].MaxCapacity).toFixed(2) : 0;
                                        this.formMachine.MaxRadio=Information[0].MaxRadio;
                                        this.formMachine.UnitMeasureIdSC=Information[0].UnitMeasureIdSC;
                                        this.formMachine.UnitMeasureIdSD=Information[0].UnitMeasureIdSD;
                                        this.formMachine.SpeedCharge=Information[0].SpeedCharge ? parseFloat(Information[0].SpeedCharge).toFixed(2) : 0;
                                        this.formMachine.SpeedDischarge=Information[0].SpeedDischarge ? parseFloat(Information[0].SpeedDischarge).toFixed(2) : 0;
                                        this.formMachine.FgOperation=Information[0].FgOperation ? true : false;
                                        this.formMachine.FgActMachine=Information[0].FgActMachine;

                                        this.originalAct = Information[0].FgActMachine;

                                        this.verifySpeedCharge = true;
                                        this.verifySpeedDischarge = true;
                                        this.verifyMaxCapacity = true;
                                        this.verifyMaxRadio = true;

                                        this.$v.$touch();
                                        this.getData();
                                    }
                                }else{
                                    this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                                }
                            } catch (error) {
                                this.$store.commit('maquina/messageMutation', error);
                                this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                            }
                        }).catch(err => {
                            this.$store.commit('maquina/messageMutation', err);
                            this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                        });
                    }
                }).catch( err => {
                    this.$store.state.maquina.messageError = err;
                    this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
                });
            }).catch( err => {
                this.$store.state.maquina.messageError = err;
                this.$store.state.planificacionestiba.apiStateForm = ENUM.ERROR;
            });
               
        }
    }
    function formReset(newValue){
        if(newValue === 3){
            this.$store.state.planificacionestiba.collapseGruas = false;
        }
    }
    function apiState(newQuestion){
        if(newQuestion === ENUM.ERROR){
            if(this.apiStateForm !== ENUM.ERROR){
                this.$notify({
                    group: 'container',
                    type: 'error',
                    ignoreDuplicates:false,
                    title: 'ERROR',
                    text: (this.messageError === '' ? this.$t('label.unexpectedError')  :  this.messageError)
                });
                this.$store.state.maquina.messageError = '';
            }
        }
    }

    function apiStateForm(newQuestion,OldQuestion){
        if (this.messageError) {
            switch (newQuestion) {
                case ENUM.ERROR:
                    this.$notify({
                        group: 'container',
                        type: 'error',
                        ignoreDuplicates:false,
                        title: 'ERROR',
                        text: (this.messageError === '' ? this.$t('label.unexpectedError')  :  this.messageError)
                    });
                    this.$store.state.maquina.messageError = '';
                
                    break;
                case ENUM.LOADED:
                    this.$notify({
                        group: 'container',
                        type: 'success',
                        ignoreDuplicates:false,
                        title: '¡Éxito!',
                        text: (this.messageError === '' ? this.$t('label.completedSuccessfully') :  this.messageError)
                    });
                    this.$store.state.maquina.messageError = '';
                    
                    break;
                default:
                    break;
            }
        }
    }
    
    function titleModal() {
        if(this.idState === 0){
            return this.$t('label.nueva')+' '+this.$t('label.machine');
        }else{
            return this.$t('label.edit')+' '+this.$t('label.machine')+': '+(this.InformationEdit.MachineName?this.InformationEdit.MachineName:'');
        }
        
    }

    function selectOptions() {
        return [
            { 
                value: true, 
                label: this.$t('label.ACTIVO')
            },
            { 
                value: false, 
                label: this.$t('label.INACTIVO')
            }
        ];
    }

    function selectOptionsDescarga(){
        return [
            { 
                value: true, 
                label: this.$t('label.yes')
            },
            { 
                value: false, 
                label: 'NO'
            }
        ];
    }
    function conditionChange(value){
        this.formMachine.MachineConditionId = value;

        const id = value;
        if (value != '') {
            this.$http.ejecutar('GET', 'MachineCondition-by-id',{ MachineConditionId: id }, '' ).then(response => {
                try {
                    if(response.status === 200){
                        const Information = response.data.data;
                        if(Information.length !== 0){
                            if(Information[0].FgSuplier === true)
                            {this.vSuplier=true;}
                            else
                            {this.vSuplier=false}
                        
                        }
                    }else{
                        this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                    }
                } catch (error) {
                    this.$store.commit('maquina/messageMutation', error);
                    this.$store.state.maquina.apiStateForm = ENUM.ERROR;
                }
            }).catch(err => {
                this.$store.commit('maquina/messageMutation', err);
                this.$store.state.maquina.apiStateForm = ENUM.ERROR;
            });
        }else{
            this.vSuplier=false;
        }
        
    }

    export default {
        name:'app-register-machine',
        data,
        props: {
            modalCraneMachine:{
                type: Boolean,
                default: false,
            },
            Crane:{
                type: Boolean,
                require: false,
                default: false,
            }
         },
        mixins: [
            ModalMixin,
        ],
        validations(){ return FormMachine(this.vSuplier) },
        directives: UpperCase,
        methods:{
            getData,
            getDataModel,
            changeBranch,
            registerData,
            closeModal,
            checkNumeros,
            conditionChange,
            errorMessage,
            hasError
        },
        components: {
            NumberInput,
            Money,
        },
        computed:{
            statusSelectColor,
            titleModal,
            idIndentification,
            optionListYear,
            optionListCondition,
            optionListUnityMesure,
            optionListType,
            optionListBranch,
            optionListModel,
            optionListMachineSuplier,
            optionListEstado,
            //verificarAlquilada,
            apiStateFormLoading,
            selectOptions,
            selectOptionsDescarga,
            computedMaxCapacity,
            computedSpeedCharge,
            computedSpeedDischarge,
            computedMaxRadio,
            ...mapState({
                idState: state => state.planificacionestiba.idState,
                apiStateForm: state => state.maquina.apiStateForm,
                messageError: state => state.maquina.messageError,
                formReset: state => state.maquina.formReset,
                branch: state => state.auth.branch
            })
        },
        watch:{
            modalCraneMachine,
            apiState,
            apiStateForm,
            formReset
        }
    }
</script>

<style lang="scss" scoped>
    .append-sm{
       height: 29px;
    }
    .text-invalid-feedback {
        width: 100%;
        margin-top: 0.25rem;
        font-size: 80%;
        color: #e55353;
    }
</style>