<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start px-2">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" />{{ $t('label.summaryOfContainersByPortLine') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end px-2">
                <CButton 
                    color="danger"
                    class="mr-1" 
                    size="sm"  
                    @click="onBtnPdf()"
                    >
                    <CIcon name="cil-file-pdf"/>&nbsp; PDF 
                </CButton>
                <CButton
                    color="excel"
                    class="mr-1"
                    size="sm"   
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"   
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-10 col-lg-3 col-xl-3 text-right', input: 'col-sm-10 col-lg-9 col-xl-9 px-0'}"
                    add-label-classes="text-right"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)"  
                    v-model="search"           
                >
                </CInput>  
            </CCol>
            <CCol sm="11" lg="3" xl="3" class="center-field">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-5 col-xl-5 text-right', input: 'col-sm-10 col-lg-7 col-xl-7 px-0'}"
                    label="U.M"
                    :options="unitMeasureOptions"
                    v-model.trim="unitMeasureId"
                    :value.sync="unitMeasureId"
                    @change="getContainerByPortLineSummary"
                />
            </CCol>
            <CCol sm="11" lg="2" xl="2" class="center-field">
            </CCol>
            <CCol sm="11" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-10 col-lg-8 col-xl-8 text-right', input: 'col-sm-10 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="11" lg="12" xl="12" class="px-2 mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowClass="getRowClass"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="formatedItems"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import Reportes from '@/_mixins/reportes';
    import AgGrid from '@/_mixins/ag-grid';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,
            unitMeasureId: '',
            unitMeasureOptions: [],
            rows: [],
            formatedItems: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],            
            columnsOption:{
                size:true,
                pol:true,
                pod:true,
                weigth:true,
                slot_operator:true,
                iso:true,
                class:true,
                setting:true,
                height:true,
                status:true,
                delivery:false,
                opt_port:true
            },
            nulo:'',
            pageSize:'',
            search:'',
            labelUnitMeasure: 'TON',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }
    function mounted() {
        this.AllgridApi = this.gridOptions.api;
        this.AllgridColumnApi = this.gridOptions.columnApi;
    }
    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    async function getUnitMeasureWeigthList () {
        this.loadingOverlay = true;
        this.unitMeasureOptions = [];
        let listado = Array;
        let i = 0;
        await this.$http.get("UnitMeasureWeigth-list", { Filter: 'ACTIVO' })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.unitMeasureOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.unitMeasureOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }     
            this.unitMeasureId = listado[0].UnitMeasureId;       

            this.loadingOverlay = false;
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            this.loadingOverlay = false;
        });
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnPdf(){
        this.loadingOverlay = true;
        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            let computedItems = [];

            computedItems.push({
                IdX: '',
                DischargePort: '',
                ShippingLineCode: '',
                Cont20Full: '20F',
                Cont20FullWeight: '20F',
                Cont20Empty: '20E',
                Cont20EmptyWeight: '20E',
                Cont40Full: '40F',
                Cont40FullWeight: '40F',
                Cont40Empty: '40E',
                Cont40EmptyWeight: '40E',
                Cont45Full: '45F',
                Cont45FullWeight: '45F',
                Cont45Empty: '45E',
                Cont45EmptyWeight: '45E',
            });
            let i = 0;
            this.formatedItems.map(function(item){
                if (i != 0) {
                    computedItems.push({
                        IdX: item.IdX,
                        DischargePort: item.DischargePort,
                        ShippingLineCode: item.ShippingLineCode,
                        Cont20Full: item.Cont20Full,
                        Cont20FullWeight: formatMilDecimal(parseFloat(item.Cont20FullWeight).toFixed(2)),
                        Cont20Empty: item.Cont20Empty,
                        Cont20EmptyWeight: formatMilDecimal(parseFloat(item.Cont20EmptyWeight).toFixed(2)),
                        Cont40Full: item.Cont40Full,
                        Cont40FullWeight: formatMilDecimal(parseFloat(item.Cont40FullWeight).toFixed(2)),
                        Cont40Empty: item.Cont40Empty,
                        Cont40EmptyWeight: formatMilDecimal(parseFloat(item.Cont40EmptyWeight).toFixed(2)),
                        Cont45Full: item.Cont45Full,
                        Cont45FullWeight: formatMilDecimal(parseFloat(item.Cont45FullWeight).toFixed(2)),
                        Cont45Empty: item.Cont45Empty,
                        Cont45EmptyWeight: formatMilDecimal(parseFloat(item.Cont45EmptyWeight).toFixed(2)),
                    })
                }
                i++;
            });
            await this.getPdf(computedItems,this.$t('label.summaryOfPlannedContainersByPortLine'),'containerByPortLineSummary');
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true; 
        if(this.nulo!="null" && this.formatedItems.length !== 0){
            let rowData = [];
            this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
            await this.getExcel(rowData,this.$t('label.summaryOfPlannedContainersByPortLine'),valor);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }   
        this.loadingOverlay = false;
    }

    function filterUnitMeasureWeight(e) {
        this.unitMeasureId=e.target.value;
        this.getContainerByPortLineSummary();
    }

    async function listarPuertos () {
        this.loadingOverlay = true;
        this.portOptions = [];
        let listado = Array;
        let i = 0;

        let PortSumaryJson = {
            StowagePlanningId: this.StowagePlanning,
            EdiFileId: this.EdiFileId,
        };

        await this.$http.post("StowagePlanningPortsSummary", PortSumaryJson, { root: 'PortSumaryJson' })
        .then(response => {
            listado = [...response.data.data];
            for (i = 0; i < listado.length; i++) {
                if (i==0) {
                    this.portOptions.push({
                        value: "",
                        label: this.$t('label.select')
                    })
                }
                this.portOptions.push({
                    value: listado[i].UnitMeasureId,
                    label: listado[i].UnitMeasureAbbrev,
                })
            }            

            this.loadingOverlay = false;
        }).catch( err => {  
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
            this.loadingOverlay = false;
        });
    }

    async function getContainerByPortLineSummary () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array;
        let i;

        let CargoSumaryJson = {
            StowagePlanningId: this.StowagePlanning,
            EdiFileId: this.EdiFileId,
            UnitMeasureId: this.unitMeasureId
        };

        await this.$http.post("StowagePlanningLoadPortSummary-by-operator", CargoSumaryJson, { root: 'CargoSumaryJson' })
        .then(response => {
            listado = [...response.data.data];
            this.nulo = String(listado[0].Json);
            if (this.nulo=="null") return false;
            this.labelUnitMeasure = this.unitMeasureId ? this.unitMeasureOptions.find(ob => ob.value == this.unitMeasureId).label : 'TON';

            this.formatedItems.push({
                section: 'total',
                Cont20Full: '20F',
                Cont20Empty: '20E',
                Cont40Full: '40F',
                Cont40Empty: '40E',
                Cont45Full: '45F',
                Cont45Empty: '45E',
            })

            for (i=0; i < listado.length; i++) {
                this.formatedItems.push({
                    IdX: listado[i].IdX,
                    DischargePort: listado[i].DischargePort,
                    ShippingLineCode: listado[i].ShippingLineCode,
                    Cont20Full: listado[i].Cont20Full,
                    Cont20FullWeight: listado[i].Cont20FullWeight,
                    Cont20Empty: listado[i].Cont20Empty,
                    Cont20EmptyWeight: listado[i].Cont20EmptyWeight,
                    Cont40Full: listado[i].Cont40Full,
                    Cont40FullWeight: listado[i].Cont40FullWeight,
                    Cont40Empty: listado[i].Cont40Empty,
                    Cont40EmptyWeight: listado[i].Cont40EmptyWeight,
                    Cont45Full: listado[i].Cont45Full,
                    Cont45FullWeight: listado[i].Cont45FullWeight,
                    Cont45Empty: listado[i].Cont45Empty,
                    Cont45EmptyWeight: listado[i].Cont45EmptyWeight,
                })
            }
            this.labelUnitMeasure = this.unitMeasureId ? this.unitMeasureOptions.find(ob => ob.value == this.unitMeasureId).label : 'TON';
this.gridOptions.api.refreshHeader();
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }



    function getRowClass(params) {
        if (params.node.data.DischargePort=="TOTAL") {
            return 'bg-edit font-weight-bold text-white'
        }

        if (params.node.data.section=="total") {
            return 'bg-cell font-weight-bold'
        }
    }
    //computed
    function columnDefs(){
        let columnDefs = [
             {
                field: "IdX",
                headerName: "#",
                lockVisible: true,
                headerClass: 'center-cell-especial',
                lockPosition: true,
                suppressMovable: true,
                minWidth: 70,
                //pinned: 'left',
                cellClass: 'gb-cell',
                //checkboxSelection: true, 
            },
            {
                field: "DischargePort",
                headerName: "DISCHARGE PORT",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                //pinned: 'left',
                cellClass: 'gb-cell',
            },
            {
                field: "ShippingLineCode",
                headerName: "CARRIER CODE",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 120,
                //pinned: 'left',
                cellClass: 'gb-cell',
            },
            {
                field: "Cont20Full",
                headerName: "Container",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                colSpan: (params) => {
                    if (isTotal(params)) {
                        return 2;
                    } else {
                        return 1;
                    }
                },
                cellClass: (params) => {
                    if (isTotal(params)) {
                        return 'center-cell-especial bg-watch';
                    } else {
                        return 'center-cell-especial';
                    }
                },
            },
            {
                field: "Cont20FullWeight",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return formatMilDecimal(variable);
                },
            },
            {
                field: "Cont20Empty",
                headerName: "Container",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                colSpan: (params) => {
                    if (isTotal(params)) {
                        return 2;
                    } else {
                        return 1;
                    }
                },
                cellClass: (params) => {
                    if (isTotal(params)) {
                        return 'center-cell-especial bg-watch';
                    } else {
                        return 'center-cell-especial';
                    }
                },
            },
            {
                field: "Cont20EmptyWeight",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return formatMilDecimal(variable);
                },
            },
            {
                field: "Cont40Full",
                headerName: "Container",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                colSpan: (params) => {
                    if (isTotal(params)) {
                        return 2;
                    } else {
                        return 1;
                    }
                },
                cellClass: (params) => {
                    if (isTotal(params)) {
                        return 'center-cell-especial bg-plan';
                    } else {
                        return 'center-cell-especial';
                    }
                },
            },
            {
                field: "Cont40FullWeight",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return formatMilDecimal(variable);
                },
            },
            {
                field: "Cont40Empty",
                headerName: "Container",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                colSpan: (params) => {
                    if (isTotal(params)) {
                        return 2;
                    } else {
                        return 1;
                    }
                },
                cellClass: (params) => {
                    if (isTotal(params)) {
                        return 'center-cell-especial bg-plan';
                    } else {
                        return 'center-cell-especial';
                    }
                },
            },
            {
                field: "Cont40EmptyWeight",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return formatMilDecimal(variable);
                },
            },
            {
                field: "Cont45Full",
                headerName: "Container",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                colSpan: (params) => {
                    if (isTotal(params)) {
                        return 2;
                    } else {
                        return 1;
                    }
                },
                cellClass: (params) => {
                    if (isTotal(params)) {
                        return 'center-cell-especial bg-edit';
                    } else {
                        return 'center-cell-especial';
                    }
                },
            },
            {
                field: "Cont45FullWeight",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                suppressMovable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return formatMilDecimal(variable);
                },
            },
            {
                field: "Cont45Empty",
                headerName: "Container",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                colSpan: (params) => {
                    if (isTotal(params)) {
                        return 2;
                    } else {
                        return 1;
                    }
                },
                cellClass: (params) => {
                    if (isTotal(params)) {
                        return 'center-cell-especial bg-edit';
                    } else {
                        return 'center-cell-especial';
                    }
                },
            },
            {
                field: "Cont45EmptyWeight",
                headerName: "WEIGHT ("+this.labelUnitMeasure+") ",
                filter: 'agTextColumnFilter',
                headerClass: 'center-cell-especial',
                sortable: true,
                lockPosition: true,
                minWidth: 160,
                cellClass: 'center-cell-especial',
                cellRenderer: params => {
                    let variable = 0.00;
                    if(params.value !== null && params.value !== '' ){    
                        let newValue =  Number.parseFloat(params.value).toFixed(2);
                        variable = formatMilDecimal(newValue);
                    }
                    return formatMilDecimal(variable);
                },
            },
        ]

      
        return columnDefs;
    }

    function isTotal(params) {
        return params.data.section === 'total';
    };
    export default {
        name: "container-by-port-line-summary",
        data,
        beforeMount,
        mounted,
        mixins: [Reportes, AgGrid],
        props: ['value'],
        methods:{
            onGridReady,
            onBtnPdf,
            onBtnExport,
            getContainerByPortLineSummary,
            getUnitMeasureWeigthList,
            filterUnitMeasureWeight,
            onQuickFilterChanged,
            getRowClass,
            onPageSizeChanged,
            listarPuertos,
        },
        computed:{
            isTotal,
            columnDefs,
            ...mapState({
                StowagePlanning: state => state.planificacionestiba.planificacionId,
                EdiFileId: state => state.planificacionestiba.EdiFileId,
                dropItem: state => state.planificacionestiba.dropItem
            }),
        },
        components:{
            
        },
        watch:{
            value: async function () {
                if (this.value==4) {
                    this.pageSize='';
                    this.unitMeasureId='';
                    this.search=''; 
                    this.onQuickFilterChanged(this.search);
                    this.gridApi.paginationSetPageSize(parseInt(50));
                    await this.getUnitMeasureWeigthList();
                    await this.getContainerByPortLineSummary();
                    this.$emit('child-refresh',true);
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
            //dropItem
        }
    };
</script>

<style>
    .ag-cell-label-container {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    #vertical-orientation {
        writing-mode: vertical-lr;
        transform: rotate(180deg);
    }
    .correct-vertical{
        transform: rotate(90deg);
    }
    .ag-header-cell-label {
        text-align:center;
        justify-content: center;
        align-items: center;
    }

    .ag-header-cell-label .ag-header-cell-text {
        text-align:center;
        justify-content: center;
        align-items: center;
    }
    .center-cell{
        text-align: center;
        vertical-align: middle;
        user-select: initial;
        -moz-user-select: text;
        -ms-user-select: text;
        -webkit-user-select: text;
    }
    .bg-cell{
        background: #dddddd;
        text-align: center;
        vertical-align: middle;
    }
    .bg-turqueza{
        background: #cafbf3;
    }
    .titulo-boton{
        margin-top: -1.5rem !important;    
        margin-right: 0.25rem !important;
    }

    .titulo-icono{
        margin-top: -0.3rem !important; margin-right: 0.5rem !important; 
    }
</style>